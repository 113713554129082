import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import c from "../components/scss/admin.module.scss";
import c2 from "../components/scss/modifFruit.module.scss";
import gsap from "gsap";
import AjoutCat from "./components/Aliments/AjoutCat";

export default function AjoutFruits() {
    const [cat, setCat] = useState([]);
    // const [item, setItem] = useState("");
    const [name, setName] = useState("");
    // const [desc, setDesc] = useState("");
    const [format, setFormat] = useState(1);
    const [chosenCat, setChosenCat] = useState(1);
    const [besoin, setBesoin] = useState(false);
    const [formats, setFormats] = useState([]);
    const [items, setItems] = useState([]);
    const [codeValid, setCodeValid] = useState(true);
    const [errorItem, setErrorItem] = useState(false);

    // const [selected, setSelected] = useState("");
    const [wentWell, setWentWell] = useState(false);
    const [wentBad, setWentBad] = useState(false);
    let select = useRef("");
    useEffect(() => {
        fetch("/api/catF")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((responseData) => {
                setCat(responseData);
            })
            .catch((err) => {
                console.error("Fetch error: ", err);
            });
        fetch("/api/fruit-format")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok for formats");
                }
                return response.json();
            })
            .then((responseData) => {
                // const arr = responseData.map((item) => item.format.name);
                setFormats(responseData);
                console.log("formats", responseData);
                fetch("/api/fruits")
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error(
                                "Network response was not ok for fruits"
                            );
                        }
                        return res.json();
                    })
                    .then((resData) => {
                        console.log("resdata", resData);
                        setItems(resData);
                    })
                    .catch((err) => {
                        console.error("Fetch error for fruits", err);
                    });
            })
            .catch((err) => {
                console.error("Fetch error: ", err);
            });
    }, []);

    console.log("chosenCat", chosenCat);
    console.log("chosen Format", format);
    console.log("type of format", typeof format);
    const handleCat = (e, param) => {
        if (e !== null) {
            setChosenCat(e.target.value);
        } else {
            console.log("pas de event");
            console.log("chosenCat en poster", param);
            if (param - 1 >= 0) {
                // setSelected(cat[param - 1].name);
                select.current = cat[param - 1].name;
                setChosenCat(param);
            } else {
                console.log(param - 1);
            }
        }
    };

    const handleSubmit = () => {
        // console.table(item, desc, format, chosenCat);
        console.log(codeValid);
        setErrorItem(!codeValid);
        if (!codeValid) {
            console.log("codeValid = ", codeValid);
            return;
        }

        if (items.filter((item2) => item2.name === name).length > 0) {
            console.log("existe deja");
            afterAdding(false);
            return;
        }
        // ici le code est correct donc on peux poursuivire les operations
        console.log("ici on est biengg");
        const data = {
            name: name,
            format: format,
            category: chosenCat,
        };
        console.log(data);
        fetch("/api/add-fruit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((data) => {
                console.log("Data saved successfully: ", data);
                afterAdding(true);
            })
            .catch((err) => {
                console.error(err);
                afterAdding(false);
            });
    };

    let timer = useRef(6);
    const [timer3, setTimer3] = useState(6);

    function afterAdding(param) {
        if (param) {
            setWentWell(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
            }, 1000);
            setTimeout(() => {
                clearInterval(timer2);
                window.location.href = "/admin/admin-gestion";
            }, 6000);
        } else {
            setWentBad(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
            }, 1000);

            setTimeout(() => {
                clearInterval(timer2);
                setWentBad(false);
                timer.current = 6;
            }, 6000);
        }
    }

    function handleItem(e) {
        console.log("changing and checking code");
        setName(e.target.value);
        // setCodeValid(validateItemCode(e.target.value.toUpperCase()));
    }

    function validateItemCode(input) {
        const pattern = /^[A-Z]{2}\d/;
        // console.log(pattern.test(input));
        return pattern.test(input);
    }

    function handleBesoin(param, paramCat) {
        // setChosenCat(param);
        handleCat(null, paramCat);
        setBesoin(param);
    }
    return (
        <>
            {besoin ? <AjoutCat besoin={handleBesoin} cat={cat} /> : null}

            {wentWell ? (
                <>
                    <div className={c2.went}>
                        <div className={c2.card}>
                            <p>{name} a bien été ajouté.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            {wentBad ? (
                <>
                    <div className={c2.went}>
                        <div className={c2.card}>
                            <p>{name} n'a pas pu être ajouté.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            <div className={c.contTout}>
                <div className={c.title}>
                    <h2>Ajouter un fruit ou un légume</h2>
                </div>
                <div className={c.contForm}>
                    <div className={c.contInputs}>
                        <div className={c.input}>
                            <label htmlFor="itemcode">Nom du produit</label>
                            {errorItem ? (
                                <p className={c.itemValid}>
                                    Le Item code doit etre composé de 2 lettres
                                    suivis de 5 chiffres. <br /> e.g.: DH12345
                                </p>
                            ) : null}

                            <input
                                id="itemcode"
                                type="text"
                                style={!errorItem ? { marginTop: 0 } : null}
                                placeholder="e.g.: bananes"
                                // className={c.item}
                                onChange={(e) => handleItem(e)}
                            />
                        </div>
                        <div className={c.input}>
                            <label htmlFor="format">Format</label>
                            <select
                                name="format"
                                id="format"
                                onChange={(e) =>
                                    setFormat(parseInt(e.target.value))
                                }
                            >
                                {formats.map((item, index) => (
                                    <option
                                        key={item.format_id}
                                        value={Number(item.format_id)}
                                    >
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={c.input}>
                            <label htmlFor="cat">Catégorie</label>
                            <select
                                name="cat"
                                ref={select}
                                id="cat"
                                onChange={(e) => handleCat(e, 0)}
                            >
                                {cat.map((item, index) => (
                                    <option key={index} value={index + 1}>
                                        {index + 1 + " - " + item.name}
                                    </option>
                                ))}
                            </select>{" "}
                            {/* <span onClick={() => handleBesoin(true)}>
                                Nouvelle catégorie ?
                            </span> */}
                        </div>
                    </div>

                    <div className={c.footer}>
                        <button
                            disabled={name === "" ? true : false}
                            onClick={handleSubmit}
                        >
                            Confirmer
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

if (document.getElementById("adminfruitsreact")) {
    const container = document.getElementById("adminfruitsreact");
    const root = createRoot(container);
    root.render(<AjoutFruits tab="home" />);
}
