import React, { useRef, useState } from "react";
import c from "../../scss/modifFruit.module.scss";

export default function ModifAlimentSpecial(props) {
    // const nameBefore = props.nameB;
    // const formatBefore = props.formatB;
    // const catBefore = props.catB;
    // const newName = props.name;
    // const newFormatData = props.format;
    // const newCatData = props.cat;
    // const newCat = props.newCat;
    // const newFormat = props.newFormat;
    const itemCode = props.itemCode;
    const itemCodeBefore = props.itemCodeB;
    const desc = props.desc;
    const descBefore = props.descB;
    const format = props.format;
    const formatBefore = props.formatB;
    const id = props.id;

    const [wentWell, setWentWell] = useState(false);
    const [wentBad, setWentBad] = useState(false);

    const handleEdit = () => {
        console.log("handleEdit");
        props.envoyer(false);
    };
    const handleSubmit = () => {
        console.log("handleSubmit");
        const leData = {
            item_code: itemCode,
            description: desc,
            format: format,
        };

        fetch(`/api/updates/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(leData),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((resData) => {
                console.log("Data updtaed successfully!", resData);
                afterUpdate(true);
            })
            .catch((err) => {
                console.error("Error updating data:", error);
                afterUpdate(false);
            });
        console.log(leData);
    };

    let timer = useRef(6);
    const [timer3, setTimer3] = useState(6);
    function afterUpdate(param) {
        if (param) {
            setWentWell(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
            }, 1000);
            setTimeout(() => {
                clearInterval(timer2);
                window.location.href = "/admin/admin-gestion";
            }, 6000);
        } else {
            setWentBad(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
            }, 1000);
            setTimeout(() => {
                clearInterval(timer2);
                setWentBad(false);
                props.envoyer(false);
            }, 6000);
        }
    }
    return (
        <>
            {wentWell ? (
                <>
                    <div className={c.went}>
                        <div className={c.card}>
                            <p>{descBefore} a bien été modifié.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            {wentBad ? (
                <>
                    <div className={c.went}>
                        <div className={c.card}>
                            <p>{descBefore} n'a pas pu être modifié.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            <div className={c.back}>
                <div className={c.contTout}>
                    <h2>Confirmation de la modification</h2>
                    <div className={c.leContent}>
                        <div className={c.contTab}>
                            <div className={c.tab}>Valeur actuelle</div>
                            <div className={c.tab}>Nouvelle Valeur</div>
                        </div>
                        <div className={c.contItems}>
                            <div className={c.elem}>
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {itemCodeBefore}
                                    </div>
                                    <div className={c.property}>{"=>"}</div>
                                    <div className={c.property}>
                                        {itemCodeBefore === itemCode
                                            ? "Valeur inchangée"
                                            : itemCode}
                                    </div>
                                </div>
                            </div>
                            <div className={c.elem}>
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {descBefore}
                                    </div>
                                    <div className={c.property}>{"=>"}</div>
                                    <div className={c.property}>
                                        {descBefore === desc
                                            ? "Valeur inchangée"
                                            : desc}
                                    </div>
                                </div>
                            </div>
                            <div className={c.elem}>
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {formatBefore}
                                    </div>
                                    <div className={c.property}>{"=>"}</div>
                                    <div className={c.property}>
                                        {formatBefore === format
                                            ? "Valeur inchangée"
                                            : format}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={c.contInfos}>
                            <h3>Attention!</h3>
                            <p>
                                Certaines valeurs sont restées inchangées.
                                <br />
                                Êtes-vous sûrs d'avoir bien fait les
                                modifications souhaitées?
                            </p>
                        </div>
                    </div>
                    <div className={c.contFooter}>
                        <div className={c.contButton}>
                            <button onClick={handleEdit}>Revenir</button>
                            <button onClick={handleSubmit}>Envoyer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
