import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
// import c from "../components/scss/fruits.module.scss";
import c from "../components/scss/fruitsBack.module.scss";
import c2 from "../components/scss/alimentsBack.module.scss";
import "../components/scss/components.scss";
import { BrowserRouter } from "react-router-dom";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import ModifFruit from "./components/Aliments/ModifFruit";
import DeleteFruit from "./components/Aliments/DeleteFruit";
// import PreviewFruit from "./components/Aliments/PreviewFruit";
// import axios from "axios";

export default function GestionFruits() {
    gsap.registerPlugin(ScrollToPlugin);

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);

    const [editing, setEditing] = useState(false);

    let exportData = useRef([]);
    const [bar, setBar] = useState("");
    const [envoyer, setEnvoyer] = useState(false);
    const [name, setName] = useState("");
    const [format, setFormat] = useState(1);
    const [chosenCat, setChosenCat] = useState(1);
    const [formats, setFormats] = useState([]);
    const [cats, setCats] = useState([]);
    const [nameBefore, setNameBefore] = useState("");
    const [formatBefore, setFormatBefore] = useState(1);
    const [catBefore, setCatBefore] = useState(1);
    const [newFormat, setNewFormat] = useState("");
    const [newCat, setNewCat] = useState("");
    const [supprimer, setSupprimer] = useState(false);
    const [id, setId] = useState(null);
    const [whatName, setWhatName] = useState("");

    const fruits = useRef(null);
    const legumes = useRef(null);
    const congele = useRef(null);
    const listeAll = useRef(null);
    const liste = useRef(null);
    const searchBar = useRef(null);
    const contAliments = useRef(null);

    let filteredData = useRef([]);
    const [cat, setCat] = useState("");

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // Define the URL of the API you want to request

        const apiUrl = "/api/fruits";

        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                return response.json(); //Parse the response body as JSON
            })
            .then((responseData) => {
                setData(responseData);

                // setData1(responseData);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Fetch error: ", error);
            });
        fetch("/api/fruit-format")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok for formats");
                }
                return response.json();
            })
            .then((responseData) => {
                setFormats(responseData);
                console.log("formats", responseData);
                fetch("/api/catF")
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error(
                                "Network response was not ok for categories"
                            );
                        }
                        return res.json();
                    })
                    .then((resData) => {
                        setCats(resData);
                        console.log("cats are : ", resData);
                    })
                    .catch((err) => {
                        console.error("Fetch error for categories : ", err);
                    });
            })
            .catch((err) => {
                console.error("Fetch error for format: ", err);
            });
    }, []);

    useEffect(() => {
        fruits.current.addEventListener("click", () => {
            setCat("fruits");
            setBar("");
            searchBar.current.value = "";
        });
        legumes.current.addEventListener("click", () => {
            setCat("vegetables");
            setBar("");
            searchBar.current.value = "";
        });
        congele.current.addEventListener("click", () => {
            setCat("frozen");
            setBar("");
            searchBar.current.value = "";
        });
    }, []);

    function handleModifier(e) {
        const id2 = e.target.getAttribute("data-id");
        const name2 = e.target.getAttribute("data-name");
        const cat2 = parseInt(e.target.getAttribute("data-cat"));
        const format2 = parseInt(e.target.getAttribute("data-format"));

        if (!editing) {
            setEditing(true);
            console.log("name", name);
            console.log("name2", name2);
            setName(name);

            setId(id2);
            setChosenCat(cat2);
            setNameBefore(name2);
            setFormatBefore(format2);
            setFormat(format2);
            setCatBefore(cat2);

            const updatedChangedData = data.filter(
                (item, index) => item.name === name2
            );
            console.log("le nouveau array :", updatedChangedData);
            setData1(updatedChangedData);
        } else {
            animAnnuler.current.restart();
            console.log("deja entrain de edit");
        }
    }

    console.log("data 1:", data1);

    function handleSupprimer(e) {
        const id2 = e.target.getAttribute("data-id");
        setId(id2);
        const leName = e.target.getAttribute("data-name");
        setWhatName(leName);
        // console.log("fonction pour supprimer ... id :", id);
        window.scrollTo({
            top: 0,
            left: 0,
        });
        toggleSupprimer(true);
    }

    function toggleSupprimer(param) {
        console.log("whaname", whatName);
        setSupprimer(param);
    }

    const whatCat = (paramCat) => {
        if (bar !== "") {
            const filtree = data.filter((item) =>
                item.name.toUpperCase().includes(bar)
            );
            filteredData.current = filtree;

            return (
                <>
                    {filteredData.current.length > 0 ? (
                        filteredData.current.map((item, index) => (
                            <div key={item.fruits_id} className={c.uneLigne}>
                                <div className={c.info}>{item.name}</div>
                                <div className={c.info}>{item.format.name}</div>
                                <div className={c.info}>
                                    {item.category.name}
                                </div>
                                <div className={c.moins}>
                                    <button
                                        data-id={item.fruits_id}
                                        data-name={item.name}
                                        data-cat={item.category.fruit_cat_id}
                                        data-format={item.format.format_id}
                                        onClick={(e) => handleModifier(e)}
                                    >
                                        Modifier ce produit
                                    </button>
                                </div>
                                <div className={c.plus}>
                                    <button
                                        data-id={item.fruits_id}
                                        data-name={item.name}
                                        onClick={(e) => handleSupprimer(e)}
                                    >
                                        Supprimmer ce produit
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className={c.uneLigne}>
                            Aucun Fruit ou Légumes pour la recherche en cours.
                        </p>
                    )}
                </>
            );
        } else {
            if (paramCat !== "") {
                // console.log(cat);
                // console.log(paramCat);
                filteredData.current = data.filter(
                    (item) =>
                        item.category.name.toLowerCase() ===
                        paramCat.toLowerCase()
                );
            } else {
                filteredData.current = data;
                console.log(filteredData.current);
            }
            return (
                <>
                    {" "}
                    {/* <p>lol</p> */}
                    {filteredData.current.map((item, index) => (
                        <div key={item.fruits_id} className={c.uneLigne}>
                            <div className={c.info}>{item.name}</div>
                            <div className={c.info}>{item.format.name}</div>
                            <div className={c.info}>{item.category.name}</div>
                            <div className={c.moins}>
                                <button
                                    data-id={item.fruits_id}
                                    data-name={item.name}
                                    data-cat={item.category.fruit_cat_id}
                                    data-format={item.format.format_id}
                                    onClick={(e) => handleModifier(e)}
                                >
                                    Modifier ce produit
                                </button>
                            </div>
                            <div className={c.plus}>
                                <button
                                    data-id={item.fruits_id}
                                    data-name={item.name}
                                    onClick={(e) => handleSupprimer(e)}
                                >
                                    Supprimmer ce produit
                                </button>
                            </div>
                        </div>
                    ))}
                </>
            );
        }
    };

    const [change, setChange] = useState(false);

    const handleEnvoyer = (param) => {
        console.log("envoyer");
        if (
            nameBefore === name &&
            formatBefore === format &&
            catBefore === catBefore
        ) {
            console.log("same");
            setChange(true);
            return;
        } else {
            console.log("not the same");
            setChange(false);
            console.log("fb", formatBefore);
            console.log("fNow", format);
            console.log("cb", catBefore);
            console.log("cNow", chosenCat);
        }
        setEnvoyer(param);

        window.scrollTo({
            top: 0,
            left: 0,
        });

        setEnvoyer(param);
    };

    const stickPoint = useRef(298);
    function handleScroll() {
        const scrollPosition = window.scrollY;
        if (scrollPosition >= stickPoint.current) {
            // Element is Sticking
            listeAll.current.style.height = "95vh";
            liste.current.style.height = "78%";
        } else {
            listeAll.current.style.height = "";
            liste.current.style.height = "";
        }
    }
    const lastElemRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    function animateLastElem() {
        if (lastElemRef.current !== null) {
            gsap.fromTo(
                lastElemRef.current,
                {
                    opacity: 0,
                    y: 20,
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                }
            );
        }
    }

    const [data1Length, setData1Length] = useState(0);

    useEffect(() => {
        // Track the length of data1 to determine if an entry added or removed

        exportData.current = data1.filter((item) => item.qty > 0);
        if (data1.filter((item) => item.qty > 0).length > data1Length) {
            // An entry was added, trigger the animation

            animateLastElem();

            // Scroll to the last entry
            const lastElem = liste.current.lastChild;
            if (lastElem !== null) {
                gsap.to(liste.current, {
                    duration: 0.4,
                    scrollTo: {
                        y: lastElem.offsetTop,
                        autoKill: false,
                    },
                    ease: "power2.inOut",
                });
            }
        }

        setData1Length(data1.filter((item) => item.qty > 0).length);
    }, [data1, data1Length]);
    const leData = {
        name: name,
        format: format,
        cat: chosenCat,
    };
    // console.log(leData);
    function handleCancel() {
        setEditing(false);
        setData1([]);
        setNameBefore("");
        setName("");
        setFormatBefore(1);
        setFormat(1);
        setCatBefore(1);
    }
    function handleName(e) {
        const name2 = e.target.value;
        setName(name2);
        setChange(false);
        const id = e.target.getAttribute("data-id") - 1;
    }

    let animAnnuler = useRef(gsap.timeline({ paused: true }));
    const annuler = useRef(null);
    useEffect(() => {
        let ctx = gsap.context(() => {
            animAnnuler.current.to(annuler.current, {
                backgroundColor: "red",
                duration: 0.4,
            });
            animAnnuler.current.to(annuler.current, {
                backgroundColor: "orange",
                duration: 0.4,
            });
        });
        return () => ctx.revert();
    }, []);

    function handleFormat(e) {
        const format2 = parseInt(e.target.value);
        setFormat(format2);
        const id = e.target.getAttribute("data-id") - 1;
        setNewFormat(formats[format2 - 1].name);
    }

    function handleCat(e) {
        const cat2 = parseInt(e.target.value);
        const id = e.target.getAttribute("data-id") - 1;
        setChosenCat(cat2);
        setNewCat(cats[cat2 - 1].name);
    }

    return (
        <>
            {/* <h1>Ici c'est l'app pour les fruits et legumes</h1> */}
            <div className={c.contTout}>
                {envoyer ? (
                    <ModifFruit
                        name={name}
                        nameB={nameBefore}
                        format={format}
                        formatB={formatBefore}
                        cat={chosenCat}
                        catB={catBefore}
                        newFormat={newFormat}
                        newCat={newCat}
                        envoyer={handleEnvoyer}
                        id={id}
                    />
                ) : null}
                {supprimer ? (
                    <DeleteFruit
                        id={id}
                        name={whatName}
                        toggle={toggleSupprimer}
                    />
                ) : null}
                <div className={c.title}>
                    <h2>Fruits et Légumes</h2>
                    <input
                        type="text"
                        ref={searchBar}
                        onChange={(e) => setBar(e.target.value.toUpperCase())}
                        onClick={() => setCat("")}
                        placeholder="Recherche"
                    />
                </div>
                <div className={c.contCol}>
                    <div ref={listeAll} className={c.contListe}>
                        <div className={c.contTop}>
                            <h2>Produit en cours de modification</h2>
                        </div>
                        {change ? (
                            <>
                                <p className={c2.invalid2}>
                                    Veuillez changer une valeur ci-dessous
                                </p>
                            </>
                        ) : null}
                        <div ref={liste} className={c.contElems}>
                            {data1.map((item, index) => (
                                <div
                                    key={item.fruits_id}
                                    className={c.elem}
                                    ref={
                                        index ===
                                        data1.filter((item) => item.qty > 0)
                                            .length -
                                            1
                                            ? lastElemRef
                                            : null
                                    }
                                >
                                    <div className={c.contProp}>
                                        <div className={c.elemTab}>Nom</div>
                                        <div className={c.code}>
                                            <input
                                                type="text"
                                                className={
                                                    change ? c.invalid : null
                                                }
                                                defaultValue={item.name}
                                                data-id={item.fruits_id}
                                                onChange={(e) => {
                                                    handleName(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={c.contProp}>
                                        <div className={c.elemTab}>Format</div>
                                        <div className={c.code}>
                                            <select
                                                name="format"
                                                className={
                                                    change ? c.invalid : null
                                                }
                                                id="format"
                                                data-id={item.format.format_id}
                                                defaultValue={
                                                    item.format.format_id
                                                }
                                                onChange={(e) => {
                                                    handleFormat(e);
                                                }}
                                            >
                                                {formats.map((item) => (
                                                    <option
                                                        key={item.format_id}
                                                        value={Number(
                                                            item.format_id
                                                        )}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={c.contProp}>
                                        <div className={c.elemTab}>
                                            Catégorie
                                        </div>
                                        <div className={c.code}>
                                            <select
                                                name="cat"
                                                className={
                                                    change ? c.invalid : null
                                                }
                                                id="cat"
                                                data-id={
                                                    item.category.fruit_cat_id
                                                }
                                                defaultValue={
                                                    item.category.fruit_cat_id
                                                }
                                                onChange={(e) => {
                                                    handleCat(e);
                                                }}
                                            >
                                                {cats.map((item) => (
                                                    <option
                                                        key={item.fruit_cat_id}
                                                        value={Number(
                                                            item.fruit_cat_id
                                                        )}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={c.contFooter}>
                            <div className={c.contButton}>
                                <button
                                    ref={annuler}
                                    onClick={handleCancel}
                                    // disabled={ ? true : false}
                                >
                                    Annuler
                                </button>
                                <button
                                    onClick={handleEnvoyer}
                                    disabled={data1.length <= 0 ? true : false}
                                >
                                    Confirmer
                                </button>
                            </div>
                            {/* <div className={c.contButton}>
                                <button
                                    onClick={handleEnvoyer}
                                    disabled={
                                        data1.filter((item) => item.qty > 0)
                                            .length <= 0
                                            ? true
                                            : false
                                    }
                                >
                                    Envoyer
                                </button>
                            </div> */}
                        </div>
                    </div>
                    <div className={c.contAliments}>
                        <div className={c.contTab1}>
                            <div
                                ref={fruits}
                                className={cat === "fruits" ? c.active : c.tab}
                            >
                                Fruits
                            </div>
                            <div
                                ref={legumes}
                                className={cat === "legumes" ? c.active : c.tab}
                            >
                                Légumes
                            </div>
                            <div
                                ref={congele}
                                className={cat === "congele" ? c.active : c.tab}
                            >
                                Congelés
                            </div>
                        </div>
                        <div className={c.contTab2}>
                            <div className={c.tab2}>Nom</div>
                            <div className={c.tab2}>Format</div>
                            <div className={c.tab2}>Catégorie</div>
                            <div className={c.tab2}>Modifier</div>
                            <div className={c.tab2}>Supprimmer</div>
                        </div>
                        <div ref={contAliments} className={c.contItems}>
                            {loading ? <p>En chargement ...</p> : whatCat(cat)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

if (document.getElementById("adminbfruit")) {
    const container = document.getElementById("adminbfruit");
    const root = createRoot(container);
    root.render(<GestionFruits tab="home" />);
}
