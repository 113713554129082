import React, { useEffect, useRef, useState } from "react";
import c from "../../scss/ajoutCat.module.scss";

export default function AjoutCat(props) {
    const cat = props.cat;
    const [newCat, setNewCat] = useState("");
    const [data, setData] = useState([]);
    const [copy, setCopy] = useState(false);

    const input = useRef(null);

    useEffect(() => {
        suggestion();
    }, [newCat]);
    function suggestion() {
        if (newCat === "") {
            setData([]);
            return;
        }

        const filtree = cat.filter((item) => item.name.includes(newCat));
        setData(filtree);
    }

    function handleExist(e) {
        const id = e.target.getAttribute("data-id");
        console.log("id", id);
        props.besoin(false, id);
    }

    function handleSubmit() {
        // console.log("submit");
        if (data.length > 0) {
            setCopy(true);
            input.current.value = "";
            return;
        }

        const patternValide = /^[a-zA-Z0-9-_]+\/[a-zA-Z0-9-_]+$/;

        // Use the test() method to check if the string matches the pattern
        if (patternValide.test(newCat)) {
            console.log("valide");
        } else {
            console.log("non valide");
        }
    }
    function handleCancel() {
        props.besoin(false, 0);
    }
    return (
        <>
            <div className={c.back}>
                <div className={c.contTout}>
                    <div className={c.title}>
                        <h2>Ajouter une catégorie</h2>
                    </div>
                    <div className={c.contInput}>
                        {copy ? (
                            <p className={c.nope}>
                                La catégorie que vous souhaitez ajouter est trop
                                similaire aux catégories existantes.
                            </p>
                        ) : null}
                        <input
                            className={copy ? c.inputRed : null}
                            ref={input}
                            type="text"
                            placeholder="e.g.: LAITIER / DAIRY"
                            onChange={(e) =>
                                setNewCat(e.target.value.toUpperCase())
                            }
                        />
                        <p className={c.info}>
                            Catégories déjà existantes qui ressemblent à votre
                            nouvelle catégorie:
                        </p>
                        <div className={c.contData}>
                            {data.map((item, index) => (
                                <p key={index}>{item.name}</p>
                            ))}
                        </div>
                    </div>
                    <div className={c.footer}>
                        <button onClick={handleCancel}>Annuler</button>
                        <button
                            onClick={handleSubmit}
                            disabled={newCat === "" ? true : false}
                        >
                            Confirmer
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
