import React, { useRef, useState } from "react";
import c from "../../scss/modifFruit.module.scss";

export default function DeleteAliment(props) {
    const id = props.id;
    const name = props.name;

    const [wentWell, setWentWell] = useState(false);
    const [wentBad, setWentBad] = useState(false);

    function handleEdit() {
        props.toggle(false);
    }
    function handleSubmit() {
        console.log("suppression de id", id);
        fetch(`/api/deletea/${id}`, {
            method: "delete",
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((data) => {
                console.log("Data deleted successfully:", data);
                afterDel(true);
            })
            .catch((err) => {
                console.error("Error deleting data:", err);
                afterDel(false);
            });
    }

    let timer = useRef(6);

    const [timer3, setTimer3] = useState(6);
    function afterDel(deleted) {
        // here we will do the popup to say that the action has been done or not
        if (deleted) {
            // What happens when the delete did go well
            setWentWell(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
                // console.log(timer.current);
            }, 1000);
            setTimeout(() => {
                clearInterval(timer2);
                // sendEmail();
                // generatePDF();
                window.location.href = "/admin/admin-gestion";
            }, 6000);
        } else {
            // What happens when the delete did not go well
            setWentBad(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
                // console.log(timer.current);
            }, 1000);
            setTimeout(() => {
                clearInterval(timer2);
                setWentBad(false);
                props.toggle(false);
            }, 6000);
        }
    }
    return (
        <>
            {wentWell ? (
                <>
                    <div className={c.went}>
                        <div className={c.card}>
                            <p>{name} a bien été supprimé.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            {wentBad ? (
                <>
                    <div className={c.went}>
                        <div className={c.card}>
                            <p>{name} n'a pas pu être supprimé.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            <div className={c.back}>
                <div className={c.contTout}>
                    <h2>Confirmation de la Suppression</h2>
                    <div className={c.leContent2}>
                        {/* <div className={c.contTab}>
                            <div className={c.tab}>Valeur actuelle</div>
                            <div className={c.tab}>Nouvelle Valeur</div>
                        </div> */}
                        <div className={c.contInfos2}>
                            <h3>Attention!</h3>
                            <p>
                                Vous êtes sur le point de supprimer un produit
                                de la base de données.
                                <br />
                                Voulez-vous procéder ?
                            </p>
                        </div>
                        <div className={c.contItems}>
                            {/* <p className={c.infoDel}>element à supprimer</p> */}
                            <div className={c.elemDel}>
                                <div className={c.inside}>
                                    <div className={c.property}>{name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={c.contFooter}>
                        <div className={c.contButton}>
                            <button onClick={handleEdit}>Revenir</button>
                            <button
                                className={c.deleteMe}
                                onClick={handleSubmit}
                            >
                                Supprimmer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
