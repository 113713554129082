import React, { useRef, useState } from "react";
import c from "../../scss/modifFruit.module.scss";

export default function ModifFruit(props) {
    const nameBefore = props.nameB;
    const formatBefore = props.formatB;
    const catBefore = props.catB;
    let newName = props.name;
    const newFormatData = props.format;
    const newCatData = props.cat;
    const newCat = props.newCat;
    const newFormat = props.newFormat;
    const id = props.id;

    const formatNames = ["box", "half a box", "fullbox", "unit", "bag"];
    const catNames = ["Fruits", "Vegetables", "Frozen"];

    const [wentWell, setWentWell] = useState(false);
    const [wentBad, setWentBad] = useState(false);

    const handleEdit = () => {
        console.log("handleEdit");
        props.envoyer(false);
    };
    const handleSubmit = () => {
        console.log("handleSubmit");
        if (newName === "") {
            newName = nameBefore;
        }
        const leData = {
            name: newName,
            format: newFormatData,
            category: newCatData,
        };

        fetch(`/api/updatef/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(leData),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((resData) => {
                console.log("Data updtaed successfully!", resData);
                afterUpdate(true);
            })
            .catch((err) => {
                console.error("Error updating data:", error);
                afterUpdate(false);
            });
        console.log(leData);
    };

    let timer = useRef(6);
    const [timer3, setTimer3] = useState(6);
    function afterUpdate(param) {
        if (param) {
            setWentWell(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
            }, 1000);
            setTimeout(() => {
                clearInterval(timer2);
                window.location.href = "/admin/admin-gestion";
            }, 6000);
        } else {
            setWentBad(true);
            let timer2 = setInterval(() => {
                timer.current -= 1;
                setTimer3(timer.current);
            }, 1000);
            setTimeout(() => {
                clearInterval(timer2);
                setWentBad(false);
                props.envoyer(false);
            }, 6000);
        }
    }
    return (
        <>
            {wentWell ? (
                <>
                    <div className={c.went}>
                        <div className={c.card}>
                            <p>{nameBefore} a bien été modifié.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            {wentBad ? (
                <>
                    <div className={c.went}>
                        <div className={c.card}>
                            <p>{nameBefore} n'a pas pu être modifié.</p>
                            <p>Vous serez redirigez dans</p>
                            <p>{timer3}</p>
                        </div>
                    </div>
                </>
            ) : null}
            <div className={c.back}>
                <div className={c.contTout}>
                    <h2>Confirmation de la modification</h2>
                    <div className={c.leContent}>
                        <div className={c.contTab}>
                            <div className={c.tab}>Valeur actuelle</div>
                            <div className={c.tab}>Nouvelle Valeur</div>
                        </div>
                        <div className={c.contItems}>
                            <div className={c.elem}>
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {nameBefore}
                                    </div>
                                    <div className={c.property}>{"=>"}</div>
                                    <div className={c.property}>
                                        {newName === ""
                                            ? "Valeur inchangée"
                                            : newName}
                                    </div>
                                </div>
                            </div>
                            <div className={c.elem}>
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {formatNames[formatBefore - 1]}
                                    </div>
                                    <div className={c.property}>{"=>"}</div>
                                    <div className={c.property}>
                                        {newFormat === ""
                                            ? "Valeur inchangée"
                                            : newFormat}
                                    </div>
                                </div>
                            </div>
                            <div className={c.elem}>
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {catNames[catBefore - 1]}
                                    </div>
                                    <div className={c.property}>{"=>"}</div>
                                    <div className={c.property}>
                                        {newCat === ""
                                            ? "Valeur inchangée"
                                            : newCat}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={c.contInfos}>
                            <h3>Attention!</h3>
                            <p>
                                Certaines valeurs sont restées inchangées.
                                <br />
                                Êtes-vous sûrs d'avoir bien fait les
                                modifications souhaitées?
                            </p>
                        </div>
                    </div>
                    <div className={c.contFooter}>
                        <div className={c.contButton}>
                            <button onClick={handleEdit}>Revenir</button>
                            <button onClick={handleSubmit}>Envoyer</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
