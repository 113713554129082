import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import c from "../components/scss/fruits.module.scss";
import "../components/scss/components.scss";
import { BrowserRouter } from "react-router-dom";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import PreviewFruit from "./components/Aliments/PreviewFruit";
// import axios from "axios";

export default function Fruits() {
    gsap.registerPlugin(ScrollToPlugin);

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);

    let exportData = useRef([]);
    const [bar, setBar] = useState("");
    const [envoyer, setEnvoyer] = useState(false);

    const fruits = useRef(null);
    const legumes = useRef(null);
    const congele = useRef(null);
    const listeAll = useRef(null);
    const liste = useRef(null);
    const searchBar = useRef(null);
    const contAliments = useRef(null);

    let filteredData = useRef([]);
    const [cat, setCat] = useState("");

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // Define the URL of the API you want to request

        const apiUrl = "/api/fruits";

        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                return response.json(); //Parse the response body as JSON
            })
            .then((responseData) => {
                setData(responseData);
                setData1(responseData);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Fetch error: ", error);
            });
    }, []);

    useEffect(() => {
        fruits.current.addEventListener("click", () => {
            setCat("fruits");
            setBar("");
            searchBar.current.value = "";
        });
        legumes.current.addEventListener("click", () => {
            setCat("vegetables");
            setBar("");
            searchBar.current.value = "";
        });
        congele.current.addEventListener("click", () => {
            setCat("frozen");
            setBar("");
            searchBar.current.value = "";
        });
    }, []);

    useEffect(() => {
        const newArray = data1.map((obj) => {
            const newObj = { ...obj };

            // Add the new "qty" property
            newObj.qty = 0;

            // Return the modified object
            return newObj;
        });
        setData1(newArray);
    }, [data]);

    function handleMinus(e) {
        // const id = e.target.getAttribute("data-id") - 1;
        const id2 = e.target.getAttribute("data-id");
        const updatedData1 = data1.map((item, index) => {
            if (item.fruits_id === parseInt(id2)) {
                // Calculate the new quantity with a minimum of 0
                const newQty = Math.max(item.qty - 1, 0);

                // Create a new object with the updated qty property
                return { ...item, qty: newQty };
            }
            return item;
        });
        setData1(updatedData1);
    }

    function handleRetirer(e) {
        // const id = e.target.getAttribute("data-id") - 1;
        const id2 = e.target.getAttribute("data-id");

        // Find the object with the corresponding id in datat1
        const updatedData1 = data1.map((item, index) => {
            if (item.fruits_id === parseInt(id2)) {
                // Create a new object with the updated qty property
                return { ...item, qty: 0 };
            }
            return item;
        });
        // Update the state of data1 with the modified array
        setData1(updatedData1);
    }

    function handlePlus(e) {
        // const id = e.target.getAttribute("data-id") - 1;
        const id2 = parseInt(e.target.getAttribute("data-id"));

        console.log(id2);
        console.log(data1);
        console.log(data1.filter((item) => item.fruits_id === id2));

        // Find the object with the corresponding id in data1
        const updatedData1 = data1.map((item, index) => {
            if (item.fruits_id === id2) {
                // Create a new object with the updated qty property
                return { ...item, qty: item.qty + 1 };
            }
            return item;
        });
        console.log(updatedData1);

        // Update the state of data1 with the modified array
        setData1(updatedData1);
    }

    const whatCat = (paramCat) => {
        if (bar !== "") {
            const filtree = data.filter((item) =>
                item.name.toUpperCase().includes(bar)
            );
            filteredData.current = filtree;

            return (
                <>
                    {filteredData.current.length > 0 ? (
                        filteredData.current.map((item, index) => (
                            <div key={item.fruits_id} className={c.uneLigne}>
                                <div className={c.info}>{item.name}</div>
                                <div className={c.info}>{item.format.name}</div>
                                <div className={c.moins}>
                                    <button
                                        data-id={item.fruits_id}
                                        onClick={(e) => handleMinus(e)}
                                    >
                                        -
                                    </button>
                                </div>
                                <div className={c.plus}>
                                    <button
                                        data-id={item.fruits_id}
                                        onClick={(e) => handlePlus(e)}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className={c.uneLigne}>
                            Aucun Fruit ou Légumes pour la recherche en cours.
                        </p>
                    )}
                </>
            );
        } else {
            if (paramCat !== "") {
                // console.log(cat);
                // console.log(paramCat);
                filteredData.current = data.filter(
                    (item) =>
                        item.category.name.toLowerCase() ===
                        paramCat.toLowerCase()
                );
            } else {
                filteredData.current = data;
                // console.log(filteredData.current);
            }
            return (
                <>
                    {" "}
                    {/* <p>lol</p> */}
                    {filteredData.current.map((item, index) => (
                        <div key={item.fruits_id} className={c.uneLigne}>
                            <div className={c.info}>{item.name}</div>
                            <div className={c.info}>{item.format.name}</div>
                            <div className={c.moins}>
                                <button
                                    data-id={item.fruits_id}
                                    onClick={(e) => handleMinus(e)}
                                >
                                    -
                                </button>
                            </div>
                            <div className={c.plus}>
                                <button
                                    data-id={item.fruits_id}
                                    onClick={(e) => handlePlus(e)}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    ))}
                </>
            );
        }
    };

    const handleEnvoyer = (param) => {
        console.log("envoyer");
        window.scrollTo(0, 0);
        setEnvoyer(param);
    };

    const stickPoint = useRef(298);
    function handleScroll() {
        const scrollPosition = window.scrollY;
        if (scrollPosition >= stickPoint.current) {
            // Element is Sticking
            listeAll.current.style.height = "95vh";
            liste.current.style.height = "78%";
        } else {
            listeAll.current.style.height = "";
            liste.current.style.height = "";
        }
    }
    const lastElemRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);

    function animateLastElem() {
        if (lastElemRef.current !== null) {
            gsap.fromTo(
                lastElemRef.current,
                {
                    opacity: 0,
                    y: 20,
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                }
            );
        }
    }

    const [data1Length, setData1Length] = useState(0);

    useEffect(() => {
        // Track the length of data1 to determine if an entry added or removed

        exportData.current = data1.filter((item) => item.qty > 0);
        if (data1.filter((item) => item.qty > 0).length > data1Length) {
            // An entry was added, trigger the animation

            animateLastElem();

            // Scroll to the last entry
            const lastElem = liste.current.lastChild;
            if (lastElem !== null) {
                gsap.to(liste.current, {
                    duration: 0.4,
                    scrollTo: {
                        y: lastElem.offsetTop,
                        autoKill: false,
                    },
                    ease: "power2.inOut",
                });
            }
        }

        setData1Length(data1.filter((item) => item.qty > 0).length);
    }, [data1, data1Length]);

    return (
        <>
            {/* <h1>Ici c'est l'app pour les fruits et legumes</h1> */}
            <div className={c.contTout}>
                {envoyer ? (
                    <PreviewFruit data={exportData} toggle={handleEnvoyer} />
                ) : null}
                <div className={c.title}>
                    <h2>Fruits et Légumes</h2>
                    <input
                        type="text"
                        ref={searchBar}
                        onChange={(e) => setBar(e.target.value.toUpperCase())}
                        onClick={() => setCat("")}
                        placeholder="Recherche"
                    />
                </div>
                <div className={c.contCol}>
                    <div ref={listeAll} className={c.contListe}>
                        <div className={c.contTop}>
                            <h2>Fruits et Légumes Sélectionnés</h2>
                        </div>
                        <div className={c.contTab}>
                            <div className={c.elemTab}>Nom</div>
                            <div className={c.elemTab}>Format</div>
                            <div className={c.elemTab}>QTÉ</div>
                            <div className={c.elemTab}>Retirer</div>
                        </div>
                        <div ref={liste} className={c.contElems}>
                            {data1
                                .filter((item) => item.qty > 0)
                                .map((item, index) => (
                                    <div
                                        key={item.fruits_id}
                                        className={c.elem}
                                        ref={
                                            index ===
                                            data1.filter((item) => item.qty > 0)
                                                .length -
                                                1
                                                ? lastElemRef
                                                : null
                                        }
                                    >
                                        <div className={c.info}>
                                            {item.name}
                                        </div>
                                        <div className={c.info}>
                                            {item.format.name}
                                        </div>
                                        <div className={c.info}>{item.qty}</div>
                                        <div className={c.retirer}>
                                            <button
                                                data-id={item.fruits_id}
                                                onClick={(e) =>
                                                    handleRetirer(e)
                                                }
                                            >
                                                x
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className={c.contFooter}>
                            <div className={c.contButton}>
                                <button
                                    onClick={handleEnvoyer}
                                    disabled={
                                        data1.filter((item) => item.qty > 0)
                                            .length <= 0
                                            ? true
                                            : false
                                    }
                                >
                                    Envoyer
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={c.contAliments}>
                        <div className={c.contTab1}>
                            <div
                                ref={fruits}
                                className={cat === "fruits" ? c.active : c.tab}
                            >
                                Fruits
                            </div>
                            <div
                                ref={legumes}
                                className={cat === "legumes" ? c.active : c.tab}
                            >
                                Légumes
                            </div>
                            <div
                                ref={congele}
                                className={cat === "congele" ? c.active : c.tab}
                            >
                                Congelés
                            </div>
                        </div>
                        <div className={c.contTab2}>
                            <div className={c.tab2}>Nom</div>
                            <div className={c.tab2}>Format</div>
                            <div className={c.tab2}>Moins</div>
                            <div className={c.tab2}>Plus</div>
                        </div>
                        <div ref={contAliments} className={c.contItems}>
                            {loading ? <p>En chargement ...</p> : whatCat(cat)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

if (document.getElementById("commandefruits")) {
    const container = document.getElementById("commandefruits");
    const root = createRoot(container);
    root.render(<Fruits tab="home" />);
}
