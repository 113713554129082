import React, { useEffect, useRef, useState } from "react";
import c from "../../scss/previewFruit.module.scss";
import c2 from "../../scss/preview.module.scss";
import { renderToStaticMarkup } from "react-dom/server";
import jsPDF from "jspdf";

export default function PreviewFruit(props) {
    console.log(props.data.current);

    const data2 = props.data;
    const [userIds, setUserIds] = useState(0);
    const [confirm, setConfirm] = useState(false);
    const [timer3, setTimer3] = useState(6);
    let timer = useRef(6);
    let userId = useRef(0);
    //
    let quelF = useRef([]);
    let emailBody;
    function getCurrentDate() {
        const now = new Date();
        const day = now.getDate().toString().padStart(2, "0");
        const month = (now.getMonth() + 1).toString().padStart(2, "0");
        const year = now.getFullYear();
        return `${year}-${month}-${day}`;
    }
    const formattedDate = getCurrentDate();
    console.log(formattedDate);
    useEffect(() => {
        fetch("/api/get-user-data", {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const user = data.user;
                userId.current = user.id;
                setUserIds(user.id);
                console.log(userId.current);
                fetch("/api/get-fr", {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        quelF.current = data;
                        console.log(data);
                        setupEmail();
                    })
                    .catch((error) => {
                        console.error("Error fetching user data: ", error);
                    });
            })
            .catch((error) => {
                console.error("Error Fetching user data : ", error);
            });
    }, []);

    let emailBodyHtml = useRef(null);
    const setupEmail = () => {
        emailBody = (
            <div className={c2.contTout} ref={contTout}>
                <h2>
                    Commande de fruits et légumes de{" "}
                    {quelF.current[userId.current]?.name}
                </h2>
                <div className={c2.leContent}>
                    <div className={c.contItems}>
                        {data2.current.map((item) => (
                            <div key={item.fruits_id} className={c2.elem}>
                                <div className={c2.insise}>
                                    <div className={c2.property}>
                                        Nom : {item.name}
                                    </div>
                                    <div className={c2.property}>
                                        Format : {item.format.name}
                                    </div>
                                    <div className={c2.qty}>
                                        Quantité : {item.qty}
                                    </div>
                                    ---------------------------------------------------
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
        emailBodyHtml.current = renderToStaticMarkup(emailBody);
        console.log("emailbody", emailBody);
        console.log("emailbodyhtml", emailBodyHtml.current);
    };

    const contTout = useRef(null);

    const generatePDF = () => {
        const docu = new jsPDF({
            format: "a4",
        });

        const itemsPerPage = 5; // Number of items per page
        const items = data2.current;

        // Track the current page number and yOffset
        let cuurentPage = 1;
        let yOffset = 10; //Initial y-offset
        console.log(quelF.current[userId.current]);

        if (cuurentPage === 1) {
            docu.text(
                `Franchise: ${quelF.current[userId.current].name}`,
                10,
                10,
                null,
                null,
                "left"
            );
            docu.text(
                `date de commande: ${formattedDate}`,
                105,
                10,
                null,
                null,
                "left"
            );
            yOffset += 30;
        }
        items.forEach((item, index) => {
            // Start with a new page when needed
            if (index > 0 && index % itemsPerPage === 0) {
                docu.addPage();
                cuurentPage++;
                yOffset = 10; // Reset the yoffset for the new page
            }

            // Render your item on the page
            docu.text(10, yOffset, `Nom : ${item.name}`);
            docu.text(10, yOffset + 10, `Format : ${item.format.name}`);
            docu.text(10, yOffset + 20, `Quantité : ${item.qty}`);
            docu.text(
                10,
                yOffset + 30,
                `--------------------------------------------------------------------------------------- `
            );
            yOffset += 50;
        });

        const docURI = docu.output("datauristring");
        const newTab = window.open();
        newTab.document.open();
        // newTab.document.write('')
        newTab.document.write(
            '<iframe width="100%" height="100%" src="' + docURI + '"></iframe>'
        );
        newTab.document.close();
    };

    console.log(userIds);

    const newData = data2.current.map((item) => ({
        user_id: userIds,
        order_date: formattedDate,
        item_orders: [
            {
                fruit_legume_id: item.fruits_id,
                qty: item.qty,
            },
        ],
    }));
    console.log("newData", newData);

    const handleSubmit = () => {
        // Make a POST request
        fetch("/api/commandsf", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(newData), // Use your JSON array here
        })
            .then((reponse) => {
                if (!reponse.ok) {
                    console.log(reponse);
                    throw new Error("Network response was not ok");
                }
                commandeComplete();
                // return reponse.json();
            })
            .then((responseData) => {
                // Handle a successful reponse here
                console.log("Data sent successfully!", responseData);
                // handleEdit();
            })
            .catch((err) => {
                // Handle errors here
                console.error("Error sending data :", err);
            });
    };

    const sendEmail = () => {
        //
        fetch("/api/send-email", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                emailBody: emailBodyHtml.current,
                franchise: quelF.current[userId.current].name,
            }),
        })
            .then((res) => {
                if (res.ok) {
                    // Handle a successful response here
                    console.log("Email sent Successfully!");
                } else {
                    // Handle an error response here
                    console.error("Error sending Email.");
                }
            })
            .catch((err) => {
                // Handle fetch error here
                console.error("Error", err);
            });
    };

    const commandeComplete = () => {
        setConfirm(true);
        let timer2 = setInterval(() => {
            timer.current -= 1;
            setTimer3(timer.current);
            console.log(timer.current);
        }, 1000);

        setTimeout(() => {
            clearInterval(timer2);
            sendEmail();
            generatePDF();
            window.location.href = "/admin/react-normal";
        }, 6000);
    };

    // console.log(data2.current);
    const handleEdit = () => {
        props.toggle(false);
    };

    return (
        <div className={c.back}>
            {confirm ? (
                <div className={c2.contConfirm}>
                    <div className={c2.contTexte}>
                        <p>La commande a été créée</p>
                        <p>Vous serez redirigé dans :</p>
                        <p>{timer3}</p>
                    </div>
                </div>
            ) : null}
            <div className={c.contTout}>
                <h2>Aperçu la commande</h2>
                <div className={c.leContent}>
                    <div className={c.contTab}>
                        <div className={c.tab}>Nom</div>
                        <div className={c.tab}>Format</div>
                        <div className={c.tab}>Quantité</div>
                    </div>
                    <div className={c.contItems}>
                        {data2.current.map((item, index) => (
                            <div key={item.fruits_id} className={c.elem}>
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {item.name}
                                    </div>
                                    <div className={c.property}>
                                        {item.format.name}
                                    </div>
                                    <div className={c.qty}>{item.qty}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={c.contFooter}>
                    <div className={c.contButton}>
                        <button onClick={handleEdit}>Éditer</button>
                        <button onClick={handleSubmit}>Envoyer</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
