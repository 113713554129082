import React, { StrictMode, useLayoutEffect, useRef } from "react";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
// import c from "../components/scss/test.module.scss";
import c from "../components/scss/aliments.module.scss";
import "../components/scss/components.scss";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/all";
import { set } from "lodash";
import Preview from "./components/Aliments/Preview";

export default function Aliments() {
    gsap.registerPlugin(ScrollToPlugin);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);

    let exportData = useRef([]);
    const [bar, setBar] = useState("");
    const [envoyer, setEnvoyer] = useState(false);

    const cat1 = useRef(null);
    const cat2 = useRef(null);
    const cat3 = useRef(null);
    const cat4 = useRef(null);
    const cat5 = useRef(null);
    const cat6 = useRef(null);
    const cat7 = useRef(null);
    const cat8 = useRef(null);
    const cat9 = useRef(null);
    const cat10 = useRef(null);
    const searchBar = useRef(null);
    const listeAll = useRef(null);
    const liste = useRef(null);
    let quant = useRef([]);
    const [quant2, setQuant2] = useState([]);

    let filteredData = useRef([]);
    const [cat, setCat] = useState("");
    // let data = useRef({});
    const [loading, setLoading] = useState(true);
    // let leData = useRef([]);
    // console.log("re-render");

    useEffect(() => {
        // Define the URL of the API you want to request
        //ici on block le scroll pour le preview
        const apiUrl = "/api/items";

        // Use the Fetch API to make a GET request
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                // console.log(typeof response.json());
                return response.json(); // Parse the response body as JSON
            })
            .then((responseData) => {
                // console.log("type repsone", typeof responseData);
                // console.log("asjkdfnb.data", responseData);
                setData(responseData); // Update the state with the data
                setData1(responseData);
                // console.log("data", data);
                setLoading(false);
                // console.log(responseData);
                // console.log(quant.current);
                // console.log("data", data.current);
                // console.log(data);
            })
            .catch((error) => {
                console.error("Fetch error:", error);
            });
    }, []);

    useEffect(() => {
        cat1.current.addEventListener("click", () => {
            setCat("LAITIER, OEUF / DAIRY, EGGS");
            setBar("");
            searchBar.current.value = "";
        });
        cat2.current.addEventListener("click", () => {
            setCat("VIANDE, VOLAILLE, POISSON / MEATS, POULTRY, FISH");
            setBar("");
            searchBar.current.value = "";
        });
        cat3.current.addEventListener("click", () => {
            setCat("CONGELÉ / FROZEN");
            setBar("");
            searchBar.current.value = "";
        });
        cat4.current.addEventListener("click", () => {
            setCat("BOISSON, SMOOTHIES / BEVERAGES, SMOOTHIES");
            setBar("");
            searchBar.current.value = "";
        });
        cat5.current.addEventListener("click", () => {
            setCat("CONDIMENTS / CONDIMENT");
            setBar("");
            searchBar.current.value = "";
        });
        cat6.current.addEventListener("click", () => {
            setCat("ÉPICE / SPICES");
            setBar("");
            searchBar.current.value = "";
        });
        cat7.current.addEventListener("click", () => {
            setCat(
                "SEC, FARINE, HUILE, CÉREAL, BOULANGERIE / DRY, FLOUR, OIL, CEREAL, BAKING"
            );
            setBar("");
            searchBar.current.value = "";
        });
        cat8.current.addEventListener("click", () => {
            setCat(" CAFÉ, THÉ, SUCRE /  COFFEE, TEA SUGAR");
            setBar("");
            searchBar.current.value = "";
        });
        cat9.current.addEventListener("click", () => {
            setCat("EMBALLAGES, ACCESSOIRES / PACKAGING, ACCESORIES");
            setBar("");
            searchBar.current.value = "";
        });
        cat10.current.addEventListener("click", () => {
            setCat("SANITAIRE / SANITARY");
            setBar("");
            searchBar.current.value = "";
        });
    }, []);

    // console.log("lengeth", data.length);
    useEffect(() => {
        const initQuant = new Array(data.length).fill(0);
        setQuant2(initQuant);
        const newArray = data1.map((obj) => {
            const newObj = { ...obj };

            // Add the new "qty" property
            newObj.qty = 0;

            // Return the modified object
            return newObj;
        });
        setData1(newArray);
    }, [data]);
    for (let i = 0; i < data.length; i++) {
        quant.current.push(0);
    }

    function handleMinus(e) {
        // const id = e.target.getAttribute("data-id") - 1;
        const leCode = e.target.getAttribute("data-code");
        // console.log(e.target.getAttribute("data-id") - 1);
        const updatedData1 = data1.map((item, index) => {
            if (item.item_code === leCode) {
                // Calculate the new quantity with a minimum of 0
                const newQty = Math.max(item.qty - 1, 0);

                // Create a new object with the updated qty property
                return { ...item, qty: newQty };
            }
            return item;
        });
        setData1(updatedData1);
    }

    function handleRetirer(e) {
        // const id = e.target.getAttribute("data-id") - 1;
        const leCode = e.target.getAttribute("data-code");

        // Find the object with the corresponding id in data1
        const updatedData1 = data1.map((item, index) => {
            if (item.item_code === leCode) {
                // Create a new object with the updated qty property
                return { ...item, qty: 0 };
            }
            return item;
        });
        // Update the state of data1 with the modified array
        setData1(updatedData1);
    }
    function handlePlus(e) {
        // const id = e.target.getAttribute("data-id") - 1;
        const leCode = e.target.getAttribute("data-code");
        console.log("leCode", leCode);

        // console.log(e.target.getAttribute("data-id") - 1);

        // Find the object with the corresponding id in data1
        const updatedData1 = data1.map((item, index) => {
            if (item.item_code === leCode) {
                // Create a new object with the updated qty property
                return { ...item, qty: item.qty + 1 };
            }
            return item;
        });
        // Update the state of data1 with the modified array
        setData1(updatedData1);

        // quant.current[id] += 1;
        // const newQuant = [...quant2];
        // newQuant[id] += 1;
        // setQuant2(newQuant);
        // console.log(quant.current[id]);
    }

    function isItItemCode(input) {
        // Define a regular expression pattern
        const pattern = /^[A-Z]{2}\d{5}$/;

        // Use the test method to check if the string matches the pattern
        return pattern.test(input);
    }
    const uneLigne1 = useRef(null);
    const uneLigne2 = useRef(null);
    let prevCat = useRef("");
    const listeElem = useRef(null);

    const whatCat = (paramCat) => {
        // const arrayPasVide = data1.filter((item) => item.qty > 0);
        // console.log("array pas vide", data1[0].qty > 0);

        if (bar !== "") {
            //param for the searchBar
            const itemCode = data.filter((item) =>
                item.description.includes(bar)
            );
            const itemCode2 = data.filter((item) =>
                item.item_code.includes(bar)
            );
            // console.log("itemCode", itemCode);
            if (
                isItItemCode(bar) ||
                itemCode.length === 0 ||
                itemCode2.length > 0
            ) {
                const filtree = data.filter((item) =>
                    item.item_code.includes(bar)
                );
                const addDesc = data.filter((item) =>
                    item.description.includes(bar)
                );

                const uniqueIds = new Set();

                filtree.forEach((item) => {
                    uniqueIds.add(item.aliment_id);
                });

                const both = [...filtree];
                addDesc.forEach((item) => {
                    if (!uniqueIds.has(item.aliment_id)) {
                        uniqueIds.add(item.aliment_id);
                        both.push(item);
                    }
                });
                // const both = filtree.concat(addDesc);
                // console.log(filtree);
                filteredData.current = both;
            } else {
                const filtree = data.filter((item) =>
                    item.description.includes(bar)
                );
                // console.log("desc", filtree);
                filteredData.current = filtree;
            }

            return (
                <>
                    {filteredData.current.length > 0 ? (
                        filteredData.current.map((item, index) => (
                            <div
                                ref={uneLigne1}
                                key={item.aliment_id}
                                className={c.uneLigne}
                            >
                                <div className={c.itemCode}>
                                    {item.item_code}
                                </div>
                                <div className={c.desc}>{item.description}</div>
                                <div className={c.format}>{item.format}</div>
                                {item.back_order === 0 ? (
                                    <>
                                        <div className={c.moins}>
                                            <button
                                                data-id={item.aliment_id}
                                                data-code={item.item_code}
                                                data-desc={item.description}
                                                data-format={item.format}
                                                onClick={(e) => handleMinus(e)}
                                            >
                                                -
                                            </button>
                                        </div>
                                        <div className={c.plus}>
                                            <button
                                                data-id={item.aliment_id}
                                                data-code={item.item_code}
                                                data-desc={item.description}
                                                data-format={item.format}
                                                onClick={(e) => handlePlus(e)}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div>BACK ORDER</div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p className={c.uneLigne}>
                            Aucun aliment pour la recherche en cours.
                        </p>
                    )}
                </>
            );
        } else {
            if (paramCat !== "" && paramCat !== "u") {
                // console.log(cat);
                // console.log(paramCat);

                filteredData.current = data.filter(
                    (item) => item.category.name === paramCat
                );
            } else {
                filteredData.current = data;
            }

            return (
                <>
                    {filteredData.current.map((item, index) => (
                        <div
                            ref={uneLigne2}
                            key={item.aliment_id}
                            className={c.uneLigne}
                        >
                            <div className={c.itemCode}>{item.item_code}</div>
                            <div className={c.desc}>{item.description}</div>
                            <div className={c.format}>{item.format}</div>
                            {item.back_order === 0 ? (
                                <>
                                    <div className={c.moins}>
                                        <button
                                            data-id={item.aliment_id}
                                            data-code={item.item_code}
                                            data-desc={item.description}
                                            data-format={item.format}
                                            onClick={(e) => handleMinus(e)}
                                        >
                                            -
                                        </button>
                                    </div>
                                    <div className={c.plus}>
                                        <button
                                            data-id={item.aliment_id}
                                            data-code={item.item_code}
                                            data-desc={item.description}
                                            data-format={item.format}
                                            onClick={(e) => handlePlus(e)}
                                        >
                                            +
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <div>BACK ORDER</div>
                            )}
                        </div>
                    ))}
                </>
            );
        }
    };

    const handleEnvoyer = (param) => {
        console.log("envoyer");
        window.scrollTo(0, 0);

        setEnvoyer(param);
    };
    const stickyPoint = useRef(298);

    function handleScroll() {
        const scrollPosition = window.scrollY;
        if (scrollPosition >= stickyPoint.current) {
            // Element is Sticking
            listeAll.current.style.height = "95vh";
            liste.current.style.height = "78%";
        } else {
            listeAll.current.style.height = "";
            liste.current.style.height = "";
        }
    }
    const contAliments = useRef(null);
    const lastElemRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);
    function animateLastElem() {
        if (lastElemRef.current !== null) {
            gsap.fromTo(
                lastElemRef.current,
                {
                    opacity: 0,
                    y: 20,
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                }
            );
        }
    }
    const [data1Length, setData1Length] = useState(0);
    useEffect(() => {
        // Track the length of data1 to determine if an entry added or removed
        // setExportData(data1.filter((item) => item.qty > 0));
        exportData.current = data1.filter((item) => item.qty > 0);
        if (data1.filter((item) => item.qty > 0).length > data1Length) {
            // console.log("data1 est plus gros que avant");
            // An entry was added, trigger the animation

            animateLastElem();

            //Scroll to the last entry
            // liste.current.scrollTop = lastElemRef.current.offsetTop;
            const lastElem = liste.current.lastChild;
            if (lastElem !== null) {
                gsap.to(liste.current, {
                    duration: 0.4,
                    scrollTo: {
                        y: lastElem.offsetTop,
                        autoKill: false, // Don't kill previously active tweens
                    },
                    ease: "power2.inOut",
                });
            }
            // lastElemRef.current.scrollIntoView({
            //     behavior: "smooth",
            //     block: "end",
            //     inline: "nearest",
            // });
        }
        // update data1Length with the current length of data1
        setData1Length(data1.filter((item) => item.qty > 0).length);
    }, [data1, data1Length]);

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            // console.log("escape");
            searchBar.current.value = "";
            setBar("");
        }
    }
    const [isListening, setIsListening] = useState(false);
    useEffect(() => {
        if (isListening) {
            // console.log("listen");
            searchBar.current.addEventListener("keypress", handleKeyPress);
        } else {
            searchBar.current.removeEventListener("keypress", handleKeyPress);
        }
    }, [isListening]);

    function startListening() {
        setIsListening(true);
    }
    function stopListening() {
        setIsListening(false);
    }
    return (
        <>
            {/* <h1>Ici c'est l'app pour les aliments</h1> */}

            <div className={c.contTout}>
                {envoyer ? (
                    <Preview data={exportData} toggle={handleEnvoyer} />
                ) : null}
                <div className={c.title}>
                    <h2>Aliments</h2>
                    <input
                        ref={searchBar}
                        onChange={(e) => setBar(e.target.value.toUpperCase())}
                        onFocus={() => startListening()}
                        onBlur={() => stopListening()}
                        onClick={() => setCat("")}
                        type="text"
                        placeholder="Recherche"
                    />
                </div>
                <div className={c.contCol}>
                    <div ref={listeAll} className={c.contListe}>
                        <div className={c.contTop}>
                            <h2>Aliments Sélectionnés</h2>
                        </div>
                        <div className={c.contTab}>
                            <div className={c.elemTab}>Item Code</div>
                            <div className={c.elemTab}>Description</div>
                            <div className={c.elemTab}>QTÉ</div>
                            <div className={c.elemTab}>Retirer</div>
                        </div>
                        <div ref={liste} className={c.contElems}>
                            {data1
                                .filter((item) => item.qty > 0)
                                .map((item, index) => (
                                    <div
                                        key={item.aliment_id}
                                        className={c.elem}
                                        ref={
                                            index ===
                                            data1.filter((item) => item.qty > 0)
                                                .length -
                                                1
                                                ? lastElemRef
                                                : null
                                        }
                                    >
                                        <div className={c.code}>
                                            {item.item_code}
                                        </div>
                                        <div className={c.desc}>
                                            {item.description}
                                        </div>
                                        <div className={c.qty}>{item.qty}</div>
                                        <div className={c.retirer}>
                                            <button
                                                data-id={item.aliment_id}
                                                data-code={item.item_code}
                                                onClick={(e) =>
                                                    handleRetirer(e)
                                                }
                                            >
                                                x
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className={c.contFooter}>
                            <div className={c.contButton}>
                                <button
                                    onClick={handleEnvoyer}
                                    disabled={
                                        data1.filter((item) => item.qty > 0)
                                            .length <= 0
                                            ? true
                                            : false
                                    }
                                >
                                    Envoyer
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={c.contAliments}>
                        <div className={c.contTab1}>
                            <div
                                ref={cat1}
                                className={
                                    cat === "LAITIER, OEUF / DAIRY, EGGS"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Laitier, oeuf / dairy , eggs
                            </div>
                            <div
                                ref={cat2}
                                className={
                                    cat ===
                                    "VIANDE, VOLAILLE, POISSON / MEATS, POULTRY, FISH"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Viande, volaille, poisson / meats, poulrty, fish
                            </div>
                            <div
                                ref={cat3}
                                className={
                                    cat === "CONGELÉ / FROZEN"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Congelé / frozen
                            </div>
                            <div
                                ref={cat4}
                                className={
                                    cat ===
                                    "BOISSON, SMOOTHIES / BEVERAGES, SMOOTHIES"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Boisson, smoothies / beverages, smoothies
                            </div>
                            <div
                                ref={cat5}
                                className={
                                    cat === "CONDIMENTS / CONDIMENT"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Condiments / condiments
                            </div>
                            <div
                                ref={cat6}
                                className={
                                    cat === "ÉPICE / SPICES" ? c.active : c.tab
                                }
                            >
                                Épices / spices
                            </div>
                            <div
                                ref={cat7}
                                className={
                                    cat ===
                                    "SEC, FARINE, HUILE, CÉREAL, BOULANGERIE / DRY, FLOUR, OIL, CEREAL, BAKING"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Sec, farine, huile, céreal / dry, flour, oil,
                                cereal, baking
                            </div>
                            <div
                                ref={cat8}
                                className={
                                    cat ===
                                    " CAFÉ, THÉ, SUCRE /  COFFEE, TEA SUGAR"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Café, thé, sucre / coffee, tea , sugar
                            </div>
                            <div
                                ref={cat9}
                                className={
                                    cat ===
                                    "EMBALLAGES, ACCESSOIRES / PACKAGING, ACCESORIES"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Emballages, accessoires / packaging, accessories
                            </div>
                            <div
                                ref={cat10}
                                className={
                                    cat === "SANITAIRE / SANITARY"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                Sanitaire / sanitary
                            </div>
                        </div>
                        <div className={c.contTab2}>
                            <div className={c.itemCode}>Item Code</div>
                            <div className={c.desc}>Description</div>
                            <div className={c.format}>Format</div>
                            <div className={c.moins}>Moins</div>
                            <div className={c.plus}>Plus</div>
                        </div>
                        <div ref={contAliments} className={c.contItems}>
                            {loading ? <p>En chargement ...</p> : whatCat(cat)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

if (document.getElementById("commandealiments")) {
    const container = document.getElementById("commandealiments");
    const root = createRoot(container);
    root.render(<Aliments tab="home" />);
}
