import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import gsap from "gsap";
import ScrollToPlugin from "gsap/all";
import ModifAlimentSpecial from "./components/Aliments/ModifAlimentSpecial";
// import c from "../components/scss/admin.module.scss";
import c from "../components/scss/alimentsBack.module.scss";
// import DeleteAliment from "./components/Aliments/DeleteAliment";
import DeleteAlimentSpecial from "./components/Aliments/DeleteAlimentSpecial";

export default function GestionAlimentsSpecial() {
    gsap.registerPlugin(ScrollToPlugin);
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [editing, setEditing] = useState(false);
    // const [dataChanged, setDataChanged] = useState([]);
    const [itemCode, setItemCode] = useState("");
    const [desc, setDesc] = useState("");
    const [format, setFormat] = useState("");

    let exportData = useRef([]);
    const [bar, setBar] = useState("");
    const [envoyer, setEnvoyer] = useState(false);

    const cat1 = useRef(null);
    const cat2 = useRef(null);
    const cat3 = useRef(null);
    // const cat4 = useRef(null);
    // const cat5 = useRef(null);
    // const cat6 = useRef(null);
    // const cat7 = useRef(null);
    // const cat8 = useRef(null);
    // const cat9 = useRef(null);
    // const cat10 = useRef(null);
    const searchBar = useRef(null);
    const listeAll = useRef(null);
    const liste = useRef(null);
    let quant = useRef([]);
    const [quant2, setQuant2] = useState([]);

    let filteredData = useRef([]);
    const [cat, setCat] = useState("");
    // let data = useRef({});
    const [loading, setLoading] = useState(true);
    // let leData = useRef([]);
    // console.log("re-render");

    useEffect(() => {
        // Define the URL of the API you want to request
        //ici on block le scroll pour le preview
        const apiUrl = "/api/items2";

        // Use the Fetch API to make a GET request
        fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                // console.log(typeof response.json());
                return response.json(); // Parse the response body as JSON
            })
            .then((responseData) => {
                // console.log("type repsone", typeof responseData);
                // console.log("asjkdfnb.data", responseData);
                setData(responseData); // Update the state with the data
                // setData1(responseData);
                // console.log("data", data);
                setLoading(false);
                // console.log(responseData);
                // console.log(quant.current);
                // console.log("data", data.current);
                // console.log(data);
            })
            .catch((error) => {
                console.error("Fetch error:", error);
            });
    }, []);

    useEffect(() => {
        cat1.current.addEventListener("click", () => {
            setCat("FRIGO / FRIGDE");
            setBar("");
            searchBar.current.value = "";
        });
        cat2.current.addEventListener("click", () => {
            setCat("CONGELÉ / FROZEN");
            setBar("");
            searchBar.current.value = "";
        });
        cat3.current.addEventListener("click", () => {
            setCat("SEC / DRY");
            setBar("");
            searchBar.current.value = "";
        });
    }, []);

    let animAnnuler = useRef(gsap.timeline({ paused: true, repeat: false }));
    let annuler = useRef(null);
    useEffect(() => {
        let ctx = gsap.context(() => {
            //ici on fait l'animation
            animAnnuler.current.to(annuler.current, {
                backgroundColor: "red",
                duration: 0.4,
            });
            animAnnuler.current.to(annuler.current, {
                backgroundColor: "orange",
                duration: 0.4,
            });
        });

        return () => ctx.revert();
    }, []);
    useEffect(() => {
        const initQuant = new Array(data.length).fill(0);
        setQuant2(initQuant);
    }, [data]);
    for (let i = 0; i < data.length; i++) {
        quant.current.push(0);
    }

    const [id, setId] = useState(null);

    const [itemCodeBefore, setItemCodeBefore] = useState("");
    const [descBefore, setDescBefore] = useState("");
    const [formatBefore, setFormatBefore] = useState("");
    const [whatName, setWhatName] = useState("");
    const [supprimer, setSupprimer] = useState(false);

    function handleModifier(e) {
        const id2 = parseInt(e.target.getAttribute("data-id"));
        console.log(id2);
        const itemCode2 = e.target.getAttribute("data-item-code");
        console.log("itemCode 2", itemCode2);
        const desc2 = e.target.getAttribute("data-desc");
        const format2 = e.target.getAttribute("data-format");
        console.log();
        if (!editing) {
            setEditing(true);
            if (itemCode === "") {
                console.log("pas bouger");
                setItemCode(itemCode2);
            }
            if (desc === "") {
                setDesc(desc2);
            }
            if (format === "") {
                setFormat(format2);
            }

            setId(id2);
            setItemCodeBefore(itemCode2);
            setDescBefore(desc2);
            setFormatBefore(format2);
            const updatedChangedData = data.filter(
                (item, index) => item.description === desc2
            );
            console.log("le nouveau array", updatedChangedData);
            setData1(updatedChangedData);
        } else {
            // setEditing(false);
            animAnnuler.current.restart();
            // gsap.to(annuler.current, {
            //     backgroundColor: "red",
            //     duration: 0.2,
            // });
            console.log("deja entrain de edit");
        }
    }

    function handleSupprimer(e) {
        const id2 = e.target.getAttribute("data-id");
        const desc2 = e.target.getAttribute("data-desc");
        console.log("fonction pour supprimer... id : ", id2);
        setId(id2);
        const leName = desc2;
        setWhatName(leName);
        toggleSupprimer(true);
        window.scrollTo({
            top: 0,
            left: 0,
        });

        // console.log(data1[id]);
    }
    function toggleSupprimer(param) {
        setSupprimer(param);
    }

    function isItItemCode(input) {
        // Define a regular expression pattern
        const pattern = /^[A-Z]{2}\d{5}$/;

        // Use the test method to check if the string matches the pattern
        return pattern.test(input);
    }
    const uneLigne1 = useRef(null);
    const uneLigne2 = useRef(null);
    let prevCat = useRef("");
    const listeElem = useRef(null);

    const whatCat = (paramCat) => {
        // const arrayPasVide = data1.filter((item) => item.qty > 0);
        // console.log("array pas vide", data1[0].qty > 0);

        if (bar !== "") {
            //param for the searchBar
            const itemCode = data.filter((item) =>
                item.description.includes(bar)
            );
            const itemCode2 = data.filter((item) =>
                item.item_code.includes(bar)
            );
            // console.log("itemCode", itemCode);
            if (
                isItItemCode(bar) ||
                itemCode.length === 0 ||
                itemCode2.length > 0
            ) {
                const filtree = data.filter((item) =>
                    item.item_code.includes(bar)
                );
                const addDesc = data.filter((item) =>
                    item.description.includes(bar)
                );

                const uniqueIds = new Set();

                filtree.forEach((item) => {
                    uniqueIds.add(item.aliment_id);
                });

                const both = [...filtree];
                addDesc.forEach((item) => {
                    if (!uniqueIds.has(item.aliment_id)) {
                        uniqueIds.add(item.aliment_id);
                        both.push(item);
                    }
                });
                // const both = filtree.concat(addDesc);
                // console.log(filtree);
                filteredData.current = both;
            } else {
                const filtree = data.filter((item) =>
                    item.description.includes(bar)
                );
                // console.log("desc", filtree);
                filteredData.current = filtree;
            }

            return (
                <>
                    {filteredData.current.length > 0 ? (
                        filteredData.current.map((item, index) => (
                            <div
                                ref={uneLigne1}
                                key={item.id}
                                className={c.uneLigne}
                            >
                                <div className={c.itemCode}>
                                    {item.item_code}
                                </div>
                                <div className={c.desc}>{item.description}</div>
                                <div className={c.format}>{item.format}</div>
                                <div className={c.moins}>
                                    <button
                                        data-id={item.id}
                                        data-item-code={item.item_code}
                                        data-desc={item.description}
                                        data-format={item.format}
                                        onClick={(e) => handleModifier(e)}
                                    >
                                        Modifier cet aliment
                                    </button>
                                </div>
                                <div className={c.plus}>
                                    <button
                                        data-id={item.id}
                                        data-desc={item.description}
                                        onClick={(e) => handleSupprimer(e)}
                                    >
                                        Supprimer cet aliment
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className={c.uneLigne}>
                            Aucun aliment pour la recherche en cours.
                        </p>
                    )}
                </>
            );
        } else {
            if (paramCat !== "" && paramCat !== "u") {
                console.log(cat);
                console.log(paramCat);

                filteredData.current = data.filter(
                    (item) => item.category.name === paramCat
                );
            } else {
                filteredData.current = data;
            }

            return (
                <>
                    {filteredData.current.map((item, index) => (
                        <div
                            ref={uneLigne2}
                            key={item.id}
                            className={c.uneLigne}
                        >
                            <div className={c.itemCode}>{item.item_code}</div>
                            <div className={c.desc}>{item.description}</div>
                            <div className={c.format}>{item.format}</div>
                            <div className={c.moins}>
                                <button
                                    data-id={item.id}
                                    data-item-code={item.item_code}
                                    data-desc={item.description}
                                    data-format={item.format}
                                    onClick={(e) => handleModifier(e)}
                                >
                                    Modifier cet aliment
                                </button>
                            </div>
                            <div className={c.plus}>
                                <button
                                    data-id={item.id}
                                    data-desc={item.description}
                                    onClick={(e) => handleSupprimer(e)}
                                >
                                    Supprimer cet aliment
                                </button>
                            </div>
                        </div>
                    ))}
                </>
            );
        }
    };

    const [codeInvalid, setCodeInvalid] = useState(false);
    const [change, setChange] = useState(false);

    const handleEnvoyer = (param) => {
        console.log("envoyer");
        const valid = isItItemCode(itemCode);
        if (valid) {
            console.log({ itemCode: itemCode, desc: desc, format: format });
            console.log({
                itemCodeBefore: itemCodeBefore,
                descBefore: descBefore,
                formatBefore,
                formatBefore,
            });
            if (
                formatBefore === format &&
                itemCodeBefore === itemCode &&
                descBefore === desc
            ) {
                console.log("test");
                setChange(true);
                return;
            } else {
                console.log("change fl");
                window.scrollTo({
                    top: 0,
                    left: 0,
                });
                setChange(false);
            }
            setEnvoyer(param);
        } else {
            setCodeInvalid(true);
            setChange(false);
            console.log("not code");
        }
    };
    const stickyPoint = useRef(298);

    function handleCancel() {
        console.log("cancel");
        // const newData1 = data1.pop();
        setData1([]);
        setChange(false);
        setEditing(false);
        setItemCodeBefore("");
        setItemCode("");
        setDescBefore("");
        setDesc("");
        setFormatBefore("");
        setFormat("");
    }

    function handleScroll() {
        const scrollPosition = window.scrollY;
        if (scrollPosition >= stickyPoint.current) {
            // Element is Sticking
            listeAll.current.style.height = "95vh";
            liste.current.style.height = "78%";
        } else {
            listeAll.current.style.height = "";
            liste.current.style.height = "";
        }
    }
    const contAliments = useRef(null);
    const lastElemRef = useRef(null);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, []);
    function animateLastElem() {
        if (lastElemRef.current !== null) {
            gsap.fromTo(
                lastElemRef.current,
                {
                    opacity: 0,
                    y: 20,
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.3,
                }
            );
        }
    }
    const [data1Length, setData1Length] = useState(0);
    useEffect(() => {
        // Track the length of data1 to determine if an entry added or removed
        // setExportData(data1.filter((item) => item.qty > 0));
        exportData.current = data1.filter((item) => item.qty > 0);
        if (data1.filter((item) => item.qty > 0).length > data1Length) {
            // console.log("data1 est plus gros que avant");
            // An entry was added, trigger the animation

            animateLastElem();

            //Scroll to the last entry
            // liste.current.scrollTop = lastElemRef.current.offsetTop;
            const lastElem = liste.current.lastChild;
            if (lastElem !== null) {
                gsap.to(liste.current, {
                    duration: 0.4,
                    scrollTo: {
                        y: lastElem.offsetTop,
                        autoKill: false, // Don't kill previously active tweens
                    },
                    ease: "power2.inOut",
                });
            }
            // lastElemRef.current.scrollIntoView({
            //     behavior: "smooth",
            //     block: "end",
            //     inline: "nearest",
            // });
        }
        // update data1Length with the current length of data1
        setData1Length(data1.filter((item) => item.qty > 0).length);
    }, [data1, data1Length]);

    function handleKeyPress(e) {
        if (e.key === "Enter") {
            e.preventDefault();
            // console.log("escape");
            searchBar.current.value = "";
            setBar("");
        }
    }
    const [isListening, setIsListening] = useState(false);
    useEffect(() => {
        if (isListening) {
            // console.log("listen");
            searchBar.current.addEventListener("keypress", handleKeyPress);
        } else {
            searchBar.current.removeEventListener("keypress", handleKeyPress);
        }
    }, [isListening]);

    function startListening() {
        setIsListening(true);
    }
    function stopListening() {
        setIsListening(false);
    }

    const handleItemCode = (e) => {
        console.log("h");
        setChange(false);
        setCodeInvalid(false);
        setItemCode(e.target.value.toUpperCase());
    };
    const handleFormat = (e) => {
        console.log("h");
        setFormat(e.target.value);
    };
    const handleDesc = (e) => {
        console.log("h");
        setDesc(e.target.value.toUpperCase());
    };

    return (
        <>
            {/* <h1>Ici c'est l'app pour les aliments</h1> */}

            <div className={c.contTout}>
                {envoyer ? (
                    <ModifAlimentSpecial
                        itemCode={itemCode}
                        itemCodeB={itemCodeBefore}
                        desc={desc}
                        descB={descBefore}
                        format={format}
                        formatB={formatBefore}
                        id={id}
                        envoyer={handleEnvoyer}
                    />
                ) : null}
                {supprimer ? (
                    <DeleteAlimentSpecial
                        id={id}
                        name={whatName}
                        toggle={toggleSupprimer}
                    />
                ) : null}
                <div className={c.title}>
                    <h2>Gestion des aliments spéciaux</h2>
                    <input
                        ref={searchBar}
                        onChange={(e) => setBar(e.target.value.toUpperCase())}
                        onFocus={() => startListening()}
                        onBlur={() => stopListening()}
                        onClick={() => setCat("")}
                        type="text"
                        placeholder="Recherche"
                    />
                </div>
                <div className={c.contCol}>
                    <div ref={listeAll} className={c.contListe}>
                        <div className={c.contTop}>
                            <h2>Aliment en cours de modification</h2>
                        </div>
                        {/* <div className={c.contTab}>
                            <div className={c.elemTab}>Item Code</div>
                            <div className={c.elemTab}>Description</div>
                            <div className={c.elemTab}>Format</div> */}
                        {/* <div className={c.elemTab}>Annuler</div> */}
                        {/* </div> */}
                        <div ref={liste} className={c.contElems}>
                            {change ? (
                                <>
                                    <p className={c.invalid2}>
                                        Veuillez changer une valeur ci-dessous.
                                    </p>
                                </>
                            ) : null}
                            {data1.map((item, index) => (
                                <div
                                    key={item.aliment_id}
                                    className={c.elem}
                                    ref={
                                        index ===
                                        data1.filter((item) => item.qty > 0)
                                            .length -
                                            1
                                            ? lastElemRef
                                            : null
                                    }
                                >
                                    <div className={c.contProp}>
                                        <div className={c.elemTab}>
                                            Item code
                                        </div>
                                        <div className={c.code}>
                                            <input
                                                type="text"
                                                className={
                                                    codeInvalid || change
                                                        ? c.invalid
                                                        : null
                                                }
                                                defaultValue={item.item_code}
                                                onChange={(e) =>
                                                    handleItemCode(e)
                                                }
                                            />
                                            {codeInvalid ? (
                                                <>
                                                    <p className={c.invalid3}>
                                                        Ce format n'est pas
                                                        valide. e.g.: AB12345
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className={c.contProp}>
                                        <div className={c.elemTab}>
                                            Description
                                        </div>
                                        <div className={c.desc}>
                                            <input
                                                type="text"
                                                defaultValue={item.description}
                                                className={
                                                    change ? c.invalid : null
                                                }
                                                onChange={(e) => handleDesc(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className={c.contProp}>
                                        <div className={c.elemTab}>Format</div>
                                        <div className={c.retirer}>
                                            <input
                                                type="text"
                                                defaultValue={item.format}
                                                className={
                                                    change ? c.invalid : null
                                                }
                                                onChange={(e) =>
                                                    handleFormat(e)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={c.contFooter}>
                            <div className={c.contButton}>
                                <button
                                    ref={annuler}
                                    onClick={handleCancel}
                                    disabled={data1.length <= 0 ? true : false}
                                >
                                    Annuler
                                </button>
                                <button
                                    onClick={handleEnvoyer}
                                    disabled={data1.length <= 0 ? true : false}
                                >
                                    Confirmer
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={c.contAliments}>
                        <div className={c.contTab1}>
                            <div
                                ref={cat1}
                                className={
                                    cat === "FRIGO / FRIGDE" ? c.active : c.tab
                                }
                            >
                                FRIGO / FRIGDE
                            </div>
                            <div
                                ref={cat2}
                                className={
                                    cat === "CONGELÉ / FROZEN"
                                        ? c.active
                                        : c.tab
                                }
                            >
                                CONGELÉ / FROZEN
                            </div>
                            <div
                                ref={cat3}
                                className={
                                    cat === "SEC / DRY" ? c.active : c.tab
                                }
                            >
                                SEC / DRY
                            </div>
                        </div>
                        <div className={c.contTab2}>
                            <div className={c.itemCode}>Item Code</div>
                            <div className={c.desc}>Description</div>
                            <div className={c.format}>Format</div>
                            <div className={c.moins}>Modifier</div>
                            <div className={c.plus}>Supprimer</div>
                        </div>
                        <div ref={contAliments} className={c.contItems}>
                            {loading ? <p>En chargement ...</p> : whatCat(cat)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    // return <div className={c.p}>BACK ORDER</div>;
}

if (document.getElementById("adminbaliments")) {
    const container = document.getElementById("adminbaliments");
    const root = createRoot(container);
    root.render(<GestionAlimentsSpecial tab="home" />);
}
