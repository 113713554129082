import React, { useEffect, useRef } from "react";

export default function Desc({ text }) {
    const divRef = useRef(null);

    useEffect(() => {
        function adjustFontSize() {
            const div = divRef.current;
            const maxWidth = div.clientWidth;
            const maxHeight = div.clientHeight;

            // Set an initial font-size
            let fontSize = 14;

            // Create a temporary span to calculate text width
            const span = document.createElement("span");
            span.style.fontSize = fontSize + "px";
            span.textContent = text;
            document.body.appendChild(span);

            // Check if the text overflows the div
            while (
                span.offsetWidth > maxWidth ||
                span.offsetHeight > maxHeight
            ) {
                fontSize--;
                span.style.fontSize = fontSize + "px";
            }

            document.body.removeChild(span);

            div.style.fontSize = fontSize + "px";
        }
        adjustFontSize();

        window.addEventListener("resize", adjustFontSize);
        return () => {
            window.removeEventListener("resize", adjustFontSize);
        };
    }, [text]);

    return (
        <div
            ref={divRef}
            style={{
                // borderBottom: "1px solid black",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "flex",
                textOverflow: "ellipsis",
                alignItems: "center",
                gridColumn: 2,
            }}
        >
            {text}
        </div>
    );
}
