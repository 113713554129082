import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
// import c from "../components/scss/admin.module.scss";
import c from "../components/scss/admin2.module.scss";
import PreviewAdmin from "./components/Aliments/PreviewAdmin";
import gsap from "gsap";

export default function AdminAppHisto() {
    const choisirF = useRef(null);
    const [fOpen, setFOpen] = useState(false);
    const [laF, setLaF] = useState("");
    const [FID, setFID] = useState(-1);
    const [sValue, setSValue] = useState("");
    const [data, setData] = useState([]);
    const [dataComm, setDataComm] = useState([]);
    const [fruits, setFruits] = useState([]);
    const [aliments, setAliments] = useState([]);
    const [special, setSpecial] = useState([]);
    const [previewData, setPreviewData] = useState([]);
    const [pOpen, setPOpen] = useState(false);
    const [userIds, setUserIds] = useState(0);
    let userId = useRef(0);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const contSelect = useRef(null);
    const carot = useRef(null);

    const [commItem, setCommItem] = useState([]);

    const contSwitch = useRef(null);
    const point = useRef(null);
    const [sToggle, setSToggle] = useState(0);

    useEffect(() => {
        // Fetch the data about the different franchisee
        fetch("/api/get-fr")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((responseData) => {
                setData(responseData);
                // console.log(responseData);
            })
            .catch((error) => {
                console.error("Fetch error: ", error);
            });

        // un petit fetch pour savoir c'est qui le user en ce moment

        fetch("/api/get-user-data", {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                const user = data.user;
                userId.current = user.id;
                setUserIds(user.id);
                console.log("userud", user.id);
            });

        // un petit fetch des commandes
        fetch("/api/get-commandes")
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((reponseData) => {
                setDataComm(reponseData);
                console.log("commandes", reponseData);
            })
            .catch((err) => {
                console.error("Fetch error: ", err);
            });

        fetch("/api/commandWithItems")
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((resData) => {
                setCommItem(resData);
                console.log("commItem au fetch", resData);
            })
            .catch((err) => {
                console.error("error lors du fetch pour le commItem", err);
            });
        fetch("/api/items")
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((resData) => {
                setAliments(resData);
                // console.log("aliments au fetch", resData);
            })
            .catch((err) => {
                console.error("error lors du fetch pour le commItem", err);
            });
        fetch("/api/fruits")
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((resData) => {
                setFruits(resData);
                // console.log("fruits au fetch", resData);
            })
            .catch((err) => {
                console.error("error lors du fetch pour le commItem", err);
            });
        fetch("/api/items2")
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Network response was not ok");
                }
                return res.json();
            })
            .then((resData) => {
                setSpecial(resData);
                // console.log("fruits au fetch", resData);
            })
            .catch((err) => {
                console.error("error lors du fetch pour le commItem", err);
            });
    }, []);
    let contSelectAnim = useRef(gsap.timeline({ repeat: false, paused: true }));
    let contSwitchAnim = useRef(gsap.timeline({ paused: true }));
    useEffect(() => {
        gsap.set(contSelect.current, {
            height: 0,
            overflow: "hidden",
            paddingTop: 0,
            paddingBottom: 0,
        });
        gsap.set(carot.current, {
            rotate: 90,
        });
        gsap.set(point.current, {
            x: 0,
        });

        contSwitchAnim.current.to(point.current, {
            x: 17,
            duration: 0.2,
        });
        contSelectAnim.current.to(contSelect.current, {
            height: 500,
            overflow: "scroll",
            // paddingTop: 20,
            // paddingBottom: 20,
            duration: 0.3,
        });
        contSelectAnim.current.to(
            carot.current,
            {
                rotate: -90,
                duration: 0.3,
            },
            "<"
        );

        // choisirF.current.addEventListener("click", handleF);
    }, []);
    // useEffect(() => {
    //     handleF();
    // }, []);
    const handleF = () => {
        if (fOpen) {
            // action pour fermer l'accordeon
            contSelectAnim.current.reverse();
            setFOpen(false);
        } else {
            // aciton pour ouvrir l'accordeon
            contSelectAnim.current.play();
            setFOpen(true);
        }
    };

    const handleFChosen = (e) => {
        const id = Number(e.target.getAttribute("data-id"));
        const name = e.target.getAttribute("data-name");
        setLaF(name);
        setFID(id);
    };

    const handleCommandClick = (command_id) => {
        fetch(`/api/commands/${command_id}`)
            .then((response) => response.json())
            .then((data) => {
                console.log("data2", data);
                setPreviewData(data);
            })
            .then(() => {
                window.scrollTo(0, 0);
                handlePreview(true);
            })
            .catch((error) => {
                console.error("Error fetching command details: ", error);
            });
    };

    function handlePreview(paramBool) {
        setPOpen(paramBool);
    }
    const searchDate = useRef(null);
    const searchElem = useRef(null);
    const handleSwitch = () => {
        if (sToggle === 0) {
            contSwitchAnim.current.play();
            // searchDate.current.value = "";
            setSValue("");
            setDateFrom(null);
            setDateTo(null);
            setSToggle(1);
        } else if (sToggle === 1) {
            contSwitchAnim.current.reverse();
            searchElem.current.value = "";
            setSValue("");
            setSToggle(0);
        }
    };

    // const handleSD = (e) => {
    //     // function to handle the date searchbar
    //     const value = e.target.value;
    //     setSValue(value);
    //     const updatedData = dataComm.filter((item) => {
    //         return item.order_date.includes(value);
    //     });
    //     console.log(updatedData);
    // };
    const handleSE = (e) => {
        // function to handle the element searchbar
        const value = e.target.value;
        setSValue(value);
    };
    function isItItemCode(input) {
        // Define a regular expression pattern
        const pattern = /^[A-Z]{2}\d{5}$/;

        // Use the test method to check if the string matches the pattern
        return pattern.test(input);
    }

    const [filtree6, setFiltree6] = useState([]);
    const whatF = (paramF, paramSearch) => {
        // console.log(paramF);
        // console.log(dataComm);

        // console.log("commItem", commItem);
        const lesItems = commItem.map((item) => item.item_orders);
        // console.log("lesItems", lesItems);

        const leTout = lesItems.map((item) => {
            item.map((leitem) => {
                if (leitem.aliment_id !== null) {
                    leitem.properties = aliments.find(
                        (elem) => elem.aliment_id === leitem.aliment_id
                    );
                } else if (leitem.fruit_legume_id !== null) {
                    // console.log(leitem.fruit_legume_id);
                    leitem.properties = fruits.find(
                        (elem) => elem.fruits_id === leitem.fruit_legume_id
                    );
                    // console.log(fruits);
                    // console.log(leitem.properties);
                } else if (leitem.special_id !== null) {
                    leitem.properties = special.find(
                        (elem) => elem.id === leitem.special_id
                    );
                }
                return leitem;
            });
            return item;
        });

        // console.log("dataCom", dataComm);
        const filteredData = leTout.map((item) => {
            item.map((item2) => {
                item2.order_info = dataComm.find(
                    (elem) => elem.command_id === item2.commande_id
                );
                // item2.order_date = new Date(item2.order_info.order_date);
                return item2;
            });
            return item;
        });
        const filteredData2 = filteredData.map((item) => {
            item.map((item2) => {
                item2.order_date = item2.order_info.order_date;
                return item2;
            });
            return item;
        });
        console.log("filteredData2", filteredData2);
        console.log("dateFrom", dateFrom);
        console.log("dateTo", dateTo);
        const flatFiltree2 = filteredData2.flat();
        const filtree4 = flatFiltree2.filter((item) => {
            const itemDate = item.order_date;

            // Assuming startDate and endDate are string format
            return itemDate >= dateFrom && itemDate <= dateTo;
        });
        console.log("flat", flatFiltree2);
        console.log("new Array", filtree4);
        if (dateFrom !== null && dateTo !== null) {
            console.log("date format");

            const filtree5 = filtree4.filter((item) => {
                return item.order_info.user_id === paramF;
            });
            console.log("filtree5", filtree5);
            setFiltree6(filtree5);

            return (
                <>
                    {filtree5.length > 0 ? (
                        filtree5.map((item, index) => (
                            <div
                                // key={item.commande_id}
                                key={index}
                                className={c.uneComm}
                                onClick={() =>
                                    handleCommandClick(item.commande_id)
                                }
                            >
                                <div className="col">{item.order_date}</div>
                                <div className="col">
                                    {item.order_info.command_type}
                                </div>
                                <div className="col">
                                    {item.order_info.total_items}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={c.noData}>
                            <p>
                                Aucune commande disponible pour cette franchise.
                            </p>
                        </div>
                    )}
                </>
            );
            // const filtreParDate = filteredData2.map((item) => {
            //     return item.map((item2) => {
            //         item2.order_date = dataComm.filter((item3) => {
            //             return item3.order_date;
            //         });
            //     });
            // });
            // console.log("filteredData", filtreParDate);
        }
        // console.log("leTout", leTout);

        if (paramSearch !== "") {
            if (paramF === -1) {
                return (
                    <>
                        <div className={c.noData}>
                            <p>
                                Veuillez choisir une franchise pour continuer la
                                recherche.
                            </p>
                        </div>
                    </>
                );
                // return "nope";
            }
            //ici on va faire le filtre avec le commItem

            const filtree = leTout.map((item2) => {
                return item2.filter((item3) => {
                    // if(isItItemCode(paramSearch)){
                    //     return item3.properties.item_code.includes(paramSearch)
                    // }
                    if (item3.properties?.item_code) {
                        // console.log("item code!");
                        return item3.properties.item_code.includes(
                            paramSearch.toUpperCase()
                        );
                    } else if (item3.properties?.name) {
                        return item3.properties.name
                            .toLowerCase()
                            .includes(paramSearch.toLowerCase());
                    }
                    return false;
                });
            });
            const flattenedFiltree = filtree.flat();
            const filtree2 = flattenedFiltree.map((item2) => {
                item2.user_id = dataComm.filter((item3) => {
                    if (item2.commande_id === item3.command_id) {
                        return item3.user_id;
                    }
                });
                return item2;
            });
            // const flatFiltree2 = filtree2.flat();
            const filtree3 = filtree2.filter((item2) => {
                // console.log(item2.user_id[0]?.user_id);
                return item2.user_id[0]?.user_id === paramF;
            });
            // console.log("les 2", filtree3);
            // console.log("filtree2", filtree2);
            // console.log("filtree", flattenedFiltree);
            // console.log("length", filtree3.length);
            // console.log("filtree3", filtree3);

            return (
                <>
                    {filtree3.length > 0 ? (
                        filtree3.map((item, index) => (
                            <div
                                // key={item.commande_id}
                                key={index}
                                className={c.uneComm}
                                onClick={() =>
                                    handleCommandClick(item.commande_id)
                                }
                            >
                                <div className="col">
                                    {item.user_id[0].order_date}
                                </div>
                                <div className="col">
                                    {item.user_id[0].command_type}
                                </div>
                                <div className="col">
                                    {item.user_id[0].total_items}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className={c.noData}>
                            <p>
                                Aucune commande disponible pour cette franchise.
                            </p>
                        </div>
                    )}
                </>
            );

            // const filtree2 = filtree.filter((item2)=>{
            //     return item2.filter((item3)=>{
            //         if()
            //     })
            // })
        } else {
            if (dataComm.filter((item) => item.user_id === paramF).length > 0) {
                return (
                    <>
                        {dataComm
                            .filter((item) => item.user_id === paramF)
                            .filter((item) => item.total_items > 0)
                            .map((item) => (
                                <div
                                    key={item.command_id}
                                    className={c.uneComm}
                                    onClick={() =>
                                        handleCommandClick(item.command_id)
                                    }
                                >
                                    <div className="col">{item.order_date}</div>
                                    <div className="col">
                                        {item.command_type}
                                    </div>
                                    <div className="col">
                                        {item.total_items}
                                    </div>
                                </div>
                            ))}
                    </>
                );
            } else {
                return (
                    <>
                        <div className={c.noData}>
                            <p>
                                Aucune commande disponible pour cette franchise.
                            </p>
                        </div>
                    </>
                );
            }
        }
    };

    function handleFrom(e) {
        const value = e.target.value;
        console.log("date From", typeof value);
        setDateFrom(value);
    }
    function handleTo(e) {
        const value = e.target.value;
        // console.log("data To", value);
        setDateTo(value);
    }

    return (
        <>
            {pOpen ? (
                <PreviewAdmin
                    handlePreview={handlePreview}
                    previewData={previewData}
                    Aliments={aliments}
                    Fruits={fruits}
                    Special={special}
                />
            ) : null}
            <p className={c.p}>admin App</p>
            <div className={c.contTout}>
                <div className={c.title}>
                    <h2>Historique de vos commandes</h2>
                </div>
                <div className={c.title2}>
                    <div className={c.contSearch}>
                        <label htmlFor="from">De</label>
                        <input
                            type="date"
                            onChange={(e) => handleFrom(e)}
                            name="from"
                            id="from"
                            disabled={sToggle === 1 ? true : false}
                        />
                        <label htmlFor="to">À</label>
                        <input
                            type="date"
                            onChange={(e) => handleTo(e)}
                            name="to"
                            id="to"
                            disabled={sToggle === 1 ? true : false}
                        />
                        <div
                            className={c.contSwitch}
                            ref={contSwitch}
                            onClick={handleSwitch}
                        >
                            <div className={c.point} ref={point}></div>
                        </div>
                        <input
                            type="text"
                            placeholder="Recherche par element"
                            ref={searchElem}
                            className={c.searchElem}
                            onChange={(e) => handleSE(e)}
                            disabled={sToggle === 0 ? true : false}
                        />
                    </div>
                </div>
                <div className={c.contCol}>
                    {/* <div className={c.contF}>
                        <h3 ref={choisirF} onClick={() => handleF()}>
                            Franchise
                            <div className={c.carot} ref={carot}>
                                ►
                            </div>
                        </h3>
                        <div ref={contSelect} className={c.contSelect}>
                            {data
                                .filter((item) => item.id > 0)
                                .map((item) => (
                                    <div
                                        key={item.id}
                                        className={c.uneF}
                                        data-id={item.id}
                                        data-name={item.name}
                                        onClick={(e) => handleFChosen(e)}
                                    >
                                        {item.name}
                                    </div>
                                ))}
                        </div>
                    </div> */}
                    <div className={c.contCommandes}>
                        <div className={c.title}>
                            {laF === "" ? (
                                <h3>Choisissez une franchise</h3>
                            ) : (
                                <h3>Historique des commandes de {laF}</h3>
                            )}
                        </div>
                        <div className={c.contTabs}>
                            <div className={c.unTabe}>Date de la commande</div>
                            <div className={c.unTabe}>Type de commande</div>
                            <div className={c.unTabe}>
                                Nombre d'items commandés
                            </div>
                        </div>
                        <div className={c.contLesComms}>
                            {whatF(userIds, sValue)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

if (document.getElementById("admincommandehistoreact")) {
    const container = document.getElementById("admincommandehistoreact");
    const root = createRoot(container);
    root.render(<AdminAppHisto tab="home" />);
}
