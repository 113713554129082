import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import c from "../../scss/preview.module.scss";
import jsPDF from "jspdf";

export default function PreviewAdmin(props) {
    // console.log(props.data.current);
    console.log("previewData", props.previewData);
    const previewData = props.previewData;
    const data22 = props.previewData.item_orders;
    const aliments = props.Aliments;
    const fruits = props.Fruits;
    const special = props.Special;
    const [isAliment, setIsAliments] = useState(false);
    const [isFruit, setIsFruit] = useState(false);
    const [isSpecial, setIsSpecial] = useState(false);
    console.log("data42", data22);
    let quelF = useRef([]);

    useEffect(() => {
        if (data22[0]?.aliment_id !== null) {
            console.log("not null for aliment");
            setIsAliments(true);
            setIsFruit(false);
            setIsSpecial(false);
        } else if (data22[0]?.fruit_legume_id !== null) {
            console.log("not null for fruits");
            setIsAliments(false);
            setIsFruit(true);
            setIsSpecial(false);
        } else if (data22[0]?.special_id != null) {
            console.log("not null for special");
            setIsAliments(false);
            setIsFruit(false);
            setIsSpecial(true);
        }

        fetch("/api/get-fr", {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        })
            .then((response) => response.json())
            .then((resData) => {
                quelF.current = resData;
            })
            .catch((err) => {
                console.error("Error fetching franchise data:", err);
            });
    }, []);

    // je vais essayer de filtrer les choses
    const data = data22.map((item) => {
        if (item.aliment_id !== null) {
            // setIsAliments(true);
            const alimentInfo = aliments.find(
                (elem) => elem.aliment_id === item.aliment_id
            );

            item.user_id = previewData.user_id;
            item.item_code = alimentInfo.item_code;
            item.description = alimentInfo.description;
            item.qty = alimentInfo.quantity;
            item.order_date = previewData.order_date;
        } else if (item.fruit_legume_id !== null) {
            // setIsFruit(true);
            const fruitInfo = fruits.find(
                (elem) => elem.fruits_id === item.fruit_legume_id
            );
            item.name = fruitInfo.name;
            item.user_id = previewData.user_id;
            item.qty = fruitInfo.quantity;
            item.order_date = previewData.order_date;
        } else if (item.special_id !== null) {
            // setIsSpecial(true);
            const specialInfo = special.find(
                (elem) => elem.id === item.special_id
            );
            item.item_code = specialInfo.item_code;
            item.user_id = previewData.user_id;
            item.description = specialInfo.description;
            item.qty = specialInfo.quanity;
            item.order_date = previewData.order_date;
        }
        return item;
    });
    console.log("data", data);

    const generatePDF = () => {
        const docu = new jsPDF({
            format: "a4",
        });

        const itemsPerPage = 5; // Number of items per page
        const items = data;

        // Track the current page number and yOffset
        let currentPage = 1;
        let yOffset = 10; // Initial y-offset

        if (currentPage === 1) {
            docu.text(
                `Franchise ${quelF.current[previewData.user_id].name}`,
                10,
                10,
                null,
                null,
                "left"
            );
            docu.text(
                `date de commande: ${previewData.order_date}`,
                105,
                10,
                null,
                null,
                "left"
            );
            yOffset += 30;
        }
        items.forEach((item, index) => {
            // Start with a new page when needed
            if (index > 0 && index % itemsPerPage === 0) {
                docu.addPage();
                currentPage++;
                yOffset = 10; // Reset the y-offset for the new page
            }

            // Render your item on the page
            if (isAliment || isSpecial) {
                docu.text(10, yOffset, `Item Code: ${item.item_code}`);
                docu.text(10, yOffset + 10, `Description: ${item.description}`);
            } else if (isFruit) {
                docu.text(10, yOffset, `Nom: ${item.name}`);
            }
            docu.text(10, yOffset + 20, `Quantité: ${item.quantity}`);
            docu.text(
                10,
                yOffset + 30,
                `--------------------------------------------------------------------------------------- `
            );
            yOffset += 50;
        });

        const docURI = docu.output("datauristring");
        const newTab = window.open();
        newTab.document.open();
        newTab.document.write(
            '<iframe width="100%" height="100%" src="' + docURI + '"></iframe>'
        );
        newTab.document.close();
    };

    function handleEdit() {
        console.log("stfu");
        props.handlePreview(false);
    }
    function handleSubmit() {
        console.log("envoyer");
        generatePDF();
    }

    return (
        <div className={c.back}>
            {/* {confirm ? (
                <div className={c.contConfirm}>
                    <div className={c.contTexte}>
                        <p>La commande a été créée.</p>
                        <p>Vous serez redirigé dans :</p>
                        <p>{timer3}</p>
                    </div>
                </div>
            ) : null} */}
            <div className={c.contTout}>
                <h2>Aperçu de la commande</h2>
                <div className={c.leContent}>
                    {isAliment || isSpecial ? (
                        <div className={c.contTab}>
                            <div className={c.tab}>Item Code</div>
                            <div className={c.tab}>Description</div>
                            <div className={c.tab}>Quantité</div>
                        </div>
                    ) : null}
                    {isFruit ? (
                        <div className={c.contTab}>
                            <div className={c.tab}>Nom</div>
                            <div className={c.tab}></div>
                            <div className={c.tab}>Quantité</div>
                        </div>
                    ) : null}
                    <div className={c.contItems}>
                        {data.map((item, index) => (
                            <div
                                key={() => {
                                    if (isAliment) {
                                        return item.aliment_id;
                                    } else if (isFruit) {
                                        return item.fruit_legume_id;
                                    } else if (isSpecial) {
                                        return item.special_id;
                                    }
                                }}
                                className={c.elem}
                            >
                                <div className={c.inside}>
                                    <div className={c.property}>
                                        {isAliment ? item.item_code : null}
                                        {isFruit ? item.name : null}
                                        {isSpecial ? item.item_code : null}
                                    </div>
                                    <div className={c.property}>
                                        {isAliment || isSpecial
                                            ? item.description
                                            : ""}
                                    </div>
                                    <div className={c.qty}>{item.quantity}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={c.contFooter}>
                    <div className={c.contButton}>
                        <button onClick={handleEdit}>Retour</button>
                        <button onClick={handleSubmit}>PDF</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
